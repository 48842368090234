import { Form, Row, Col, Select, Button, Input } from 'antd';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { QueryBuilder as ReactFilterBuilder } from 'react-querybuilder';
import { useEffect, useState } from 'react';
import { getTableSchemasApi } from '@/src/modules/reports/api/reports';
import Repeater from '../Repeater';
import Icon from '../Icon';
import Loading from '../Loading';
import { useLayout } from '@/src/layouts/Layout';
import QueryConverter from '@/src/utils/query-converter';

export function createQueryFromJsonContext(json_context) {
  let query_converter = new QueryConverter(json_context);

  return query_converter.toSql();
}

const FilterBuilder = ({ fields, value, onChange, ...props }) => (
  <QueryBuilderAntD>
    <ReactFilterBuilder
      fields={fields}
      query={value}
      onQueryChange={onChange}
      controlClassnames={{ queryBuilder: 'justifiedLayout' }}
      {...props}
    />
  </QueryBuilderAntD>
);

export default function QueryBuilder({ disabled, ...props }) {
  const [loadingSchema, setLoadingSchema] = useState(true);
  const [schema, setSchema] = useState([]);
  const [fields, setFields] = useState([]);
  const [columnMap, setColumnMap] = useState({});
  const [sortableColumnMap, setSortableColumnMap] = useState({});
  const { message } = useLayout();
  const [tableOptions, setTableOptions] = useState([]);
  const aggregatorOptions = ['COUNT(*)'].map((i) => ({ label: i, value: i }));
  let form = Form.useFormInstance();
  let selected_table = form.getFieldValue([...props.name, 'table_name']);
  let selected_columns = form.getFieldValue([...props.name, 'columns']);
  let aggregator = form.getFieldValue([...props.name, 'aggregator']);

  useEffect(() => {
    getTableSchemasApi().then((data) => {
      setSchema(data);
      setTableOptions(
        data.map(({ tableName, tableDisplayName }) => ({
          label: tableDisplayName,
          value: tableName
        }))
      );
      setLoadingSchema(false);
    });
  }, []);

  useEffect(() => {
    if (selected_table) {
      setFieldsForTable(selected_table);
    }
    // eslint-disable-next-line
  }, [schema, selected_table]);

  function setFieldsForTable(table_name) {
    let table = schema.find((item) => item.tableName === table_name);
    if (table) {
      setFields(
        table.columns.map((i) => ({
          label: i.display_name,
          name: i.column_name,
          placeholder: `Enter ${i.display_name}`,
          ...(i.type === 'numeric' ? { inputType: 'number' } : {}),
          ...(i.type === 'timestamp' ? { inputType: 'datetime-local' } : {}),
          data_type: i.type,
          validator(r) {
            return !!r.value;
          }
        }))
      );
    }
  }

  useEffect(() => {
    let map = {};
    fields.forEach((i) => (map[i.name] = { label: i.label, value: i.name, dataType: i.data_type }));
    setColumnMap(map);
  }, [fields]);

  useEffect(() => {
    let map = {};

    if (aggregator?.fn) {
      map[aggregator.alias || aggregator.fn.replace(/\(.+\)/, '').toLowerCase()] = {
        label: aggregator.alias || aggregator.fn.replace(/\(.+\)/, '').toLowerCase(),
        value: aggregator.alias || aggregator.fn.replace(/\(.+\)/, '').toLowerCase()
      };
    }

    (selected_columns || [])
      .filter((i) => i.column && columnMap[i.column])
      .forEach((item) => (map[item.column] = columnMap[item.column]));

    setSortableColumnMap(map);
  }, [selected_columns, columnMap, aggregator]);

  function handleSelectedTableChanged(table_name) {
    if (table_name) {
      let table = schema.find((item) => item.tableName === table_name);
      form.setFieldValue([...props.name, 'tableOptions'], table.tableOptions);
      form.setFieldValue([...props.name, 'filters'], {
        combinator: 'and',
        rules: []
      });
      form.setFieldValue([...props.name, 'columns'], [{ column: undefined, alias: undefined }]);
      form.setFieldValue([...props.name, 'group_by'], []);
      form.setFieldValue([...props.name, 'aggregator'], undefined);
      form.setFieldValue([...props.name, 'sorting'], undefined);

      setFieldsForTable(table_name);
    }
  }

  function handleSelectAllColumns() {
    form.setFieldValue(
      [...props.name, 'columns'],
      fields.map((field) => ({ column: field.name, alias: field.label }))
    );
  }

  function getCurrentQuery() {
    form.validateFields(['query_context'], { recursive: true }).then((result) => {
      navigator.clipboard.writeText(
        createQueryFromJsonContext(form.getFieldValue([...props.name]))
      );
      message.success('Query Copied to clipboard');
    });
  }

  function handleReset() {
    handleSelectedTableChanged(selected_table);
  }

  if (loadingSchema) {
    return <Loading />;
  }

  return (
    <Row>
      <Col span={24}>
        <Form.Item label="Module" name={[...props.name, 'table_name']} rules={[{ required: true }]}>
          <Select
            placeholder="Select Module"
            options={tableOptions}
            onChange={(value) => {
              form.setFieldValue([...props.name, 'table_name'], value);
              handleSelectedTableChanged(value);
            }}
          />
        </Form.Item>
      </Col>
      {form.getFieldValue([...props.name, 'table_name']) ? (
        <>
          {!disabled && (
            <div className="text-right w-full">
              <Button type="link" onClick={getCurrentQuery}>
                Copy Current Query
              </Button>
              <Button type="link" onClick={handleSelectAllColumns}>
                Select All Columns
              </Button>
              <Button type="link" onClick={handleReset}>
                Reset
              </Button>
            </div>
          )}
          <Col span={24} className="border-solid border-border bg-border rounded px-2">
            <Repeater
              name={[...props.name, 'columns']}
              key={selected_table}
              disabled={disabled}
              defaultItem={{ column: undefined, alias: undefined }}
              addBtnText={'Add Column'}>
              {({ key, name: innerName, index, ...restField }, actions) => (
                <Row key={key}>
                  <Col span={24}>
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <div className={`flex-1 mr-2 py-1`}>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label="Attribute"
                                name={[innerName, 'column']}
                                rules={[{ required: true }]}>
                                <Select
                                  placeholder="Select Attribute"
                                  showSearch
                                  options={Object.values(columnMap)}
                                  onChange={(item) => {
                                    let field = columnMap[item];
                                    form.setFieldValue(
                                      [...props.name, 'columns', innerName, 'column'],
                                      item
                                    );
                                    form.setFieldValue(
                                      [...props.name, 'columns', innerName, 'alias'],
                                      field?.label
                                    );
                                    form.setFieldValue(
                                      [...props.name, 'columns', innerName, 'dataType'],
                                      field?.dataType
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="flex-shrink-0 px-2 flex items-center">
                          {!disabled && (
                            <Button
                              shape="circle"
                              className="mr-1"
                              title="Move Up"
                              disabled={index === 0}
                              onClick={() => actions.move(index, index - 1)}>
                              <ArrowUpOutlined />
                            </Button>
                          )}
                          {!disabled && (
                            <Button
                              shape="circle"
                              className="mr-1"
                              title="Move Down"
                              disabled={index === actions.length - 1}
                              onClick={() => actions.move(index, index + 1)}>
                              <ArrowDownOutlined />
                            </Button>
                          )}
                          {!disabled && (
                            <Button
                              shape="circle"
                              type="danger"
                              style={{ visibility: innerName === 0 ? 'hidden' : 'visible' }}
                              onClick={() => actions.remove(innerName)}>
                              <Icon
                                name="close"
                                className="text-danger"
                                style={{ fontSize: '1.5rem' }}
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Repeater>
          </Col>
          <Col span={24} className="mb-4" />
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Aggregation" name={[...props.name, 'aggregator', 'fn']}>
                  <Select options={aggregatorOptions} placeholder="Select Aggregation" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Aggregation Alias" name={[...props.name, 'aggregator', 'alias']}>
                  <Input
                    placeholder="ex. Total Count"
                    onChange={(event) => {
                      form.setFieldValue(
                        [...props.name, 'aggregator', 'alias'],
                        event.target.value
                      );
                      form.setFieldValue([...props.name, 'sorting', 'column'], undefined);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Filters"
              name={[...props.name, 'filters']}
              rules={[{ required: true }]}>
              <FilterBuilder fields={fields} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Sorting" name={[...props.name, 'sorting', 'column']}>
                  <Select
                    options={Object.values(sortableColumnMap)}
                    placeholder="Select Attribute"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Sorting Direction" name={[...props.name, 'sorting', 'direction']}>
                  <Select
                    placeholder="Select Direction"
                    options={[
                      { label: 'Ascending', value: 'ASC' },
                      { label: 'Descending', value: 'DESC' }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Limit Results"
                  name={[...props.name, 'limit']}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value && isNaN(value)) {
                          return Promise.reject('Limit has to be a number.');
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}>
                  <Input placeholder="No. of results" allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      ) : null}
    </Row>
  );
}
