import Moment from 'moment';
import Uniq from 'lodash/uniq';
import Template from 'lodash/template';
import { getAllowedUnit } from '@utils/unit-checker';
import applyUnit from '@utils/unit-applier';
import NumberFormat from '@src/utils/number-format';

export default class TooltipBuilder {
  _points = [];
  _context = null;
  _options = {};
  _allPointCounters = [];
  _baseCounter = null;

  constructor(data, options, context) {
    this._context = context;
    this._options = options;
    this.series = data;

    this._points = Array.isArray(this._context) ? this._context : [this._context];
    let allPointCounters = [];
    this._points.forEach((point, index) => {
      const series = data.find((d) => d.name === point.seriesName);
      if (series) {
        this._points[index].series = series;
        this._baseCounter = series.counter;
        allPointCounters.push(series.counter);
      }
    });
    this._allPointCounters = Uniq(allPointCounters);
  }

  __getDateTimeHtml() {
    const momentInstance = this._options.dateTime
      ? Moment.tz(
          this._points[0].axisValue || this._points[0].value[this._points[0].encode.x[0]],
          'UTC'
        ).tz(this._options.timezone || 'UTC')
      : {
          isValid() {
            return false;
          }
        };
    const x = momentInstance.isValid()
      ? momentInstance.format(this._options.dateFormat)
      : this._points[0].name !== undefined
      ? this._points[0].name
      : this._points[0].name;
    let tooltipHeader = '';

    // add date time on header only if multiple points
    tooltipHeader +=
      momentInstance.isValid() || (x && String(x).indexOf('highchart') === -1)
        ? `<div style="font-size: 11px" class="font-bold">${x}</div>`
        : '';

    return tooltipHeader;
  }

  __getRangeHtml(point, value) {
    return `<span class="value font-bold">${point.data.source} - ${point.data.target} : ${value}</span>`;
  }

  __getLowHighHtml(point, highPoint, counter) {
    return `<span class="value font-bold">${
      counter
        ? applyUnit(counter, point.data[point.encode.y[0]])
        : NumberFormat(point.data[point.encode.y[0]])
    } - ${
      counter
        ? applyUnit(counter, highPoint.data[point.encode.y[0]])
        : NumberFormat(highPoint.data[point.encode.y[0]])
    }</span>`;
  }

  __getDurationHtml(point, value) {
    return `<span class="value font-bold">${value} </span><div>(${point.data.duration})</div>`;
  }

  __getDefaultValueHtml(value) {
    return `<span class="value font-bold shrink-0	${
      this._allPointCounters.length === 1 ? 'text-sm' : 'text-sm'
    }" style="flex-shrink: 0">${value}</span>`;
  }

  __getSinglePointHtml(point) {
    const series = point.series;
    if (!series) {
      return '';
    }
    let legend = point.marker;
    if (point.series && (point.series.lowerbound || point.series.upperbound)) {
      legend = this._points.find((p) => !p.series.lowerbound && !p.series.upperbound).marker;
    }
    let rawValue = Array.isArray(point.value)
      ? point.value[point.axisType === 'yAxis.time' ? point.encode.x[0] : point.encode.y[0]]
      : point.value !== undefined
      ? point.value
      : '';
    let counter = (point.series || {}).counter || this._baseCounter || '';
    counter = counter.replace(/[~^]/g, '.');
    // if (point.series.type === 'sankey') {
    //   if (point.isNode) {
    //     rawValue = point.sum
    //   } else {
    //     rawValue = point.weight
    //   }
    // }
    // eslint-disable-next-line
    let value = this._options.tooltipFormatter
      ? this._options.tooltipFormatter(rawValue)
      : counter && getAllowedUnit(counter)
      ? applyUnit(counter, rawValue)
      : `${NumberFormat(rawValue)}${this._options.unit ? ` ${this._options.unit}` : ''}`;
    if (!counter && ((point.series || {}).formattedValues || []).length > 0) {
      value = point.series.formattedValues[point.axisIndex];
    }
    let valueHtml = '';
    let instance = this._options.useInstanceInTooltip ? series.instance : null;
    let counterName = (series || {}).name.replace(/[~^]/g, '.');
    let seriesName = `${counterName} ${
      instance && (series || {}).name.indexOf(instance) === -1 ? `(${instance}) ` : ''
    }`;
    if (point.series && point.series.upperbound) {
      value = null;
    }
    if (point.data && point.data.source && point.data.target) {
      valueHtml = this.__getRangeHtml(point, value);
    } else if (point.series && point.series.lowerbound) {
      valueHtml = this.__getLowHighHtml(
        point,
        this._points.find((p) => p.series.upperbound),
        counter
      );
    } else {
      if (point.data && point.data.duration) {
        valueHtml = this.__getDurationHtml(point, value);
      } else {
        valueHtml = this.__getDefaultValueHtml(value);
      }
    }
    if (['pie', 'sankey'].includes(((this._points[0] || {}).series || {}).type)) {
      if (point.dataType !== 'edge') {
        seriesName += `${this.__getDateTimeHtml()}`;
      }
    }
    return value
      ? Template(
          `
      <div class="flex justify-between w-full items-center">
        <div class="flex h-full ${
          // this._points.length === 1 &&
          ((this._points[0] || {}).series || {}).type !== 'pie' ? 'self-start' : 'items-center'
        }" ${
            // this._points.length === 1 &&
            ((this._points[0] || {}).series || {}).type !== 'pie' ? 'style="margin-top: 6px"' : ''
          }>
          <div class="flex items-center">
            <%= legend %>
          </div>
        </div>
        <div class="min-w-0 mr-auto">
          <%= seriesName %>
        </div>
        <div class="flex-shrink-0 justify-end flex ml-6">
          <%= value %>
        </div>
      </div>
    `
        )({
          legend,
          seriesName,
          value: valueHtml
        })
      : '';
  }

  __getTooltipBody() {
    let tooltipBody = `<div class="flex flex-col min-w-0 min-h-0 " style="max-width: 50vw; font-size:11px">
      ${this._points.map((point) => this.__getSinglePointHtml(point.point || point)).join('')}
      </div>`;

    return tooltipBody;
  }

  html() {
    let tooltipHeader = '';
    let tooltipBody = this.__getTooltipBody();
    if (['pie', 'sankey'].includes(((this._points[0] || {}).series || {}).type) === false) {
      tooltipHeader = `<div class="flex flex-col" style="max-width: 50vw">${this.__getDateTimeHtml()}</div>`;
    }
    // else {
    //   tooltipBody += this.__getDateTimeHtml()
    // }

    return `${tooltipHeader}${tooltipBody}`;
  }
}
