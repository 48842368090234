import { Row, Col, Form, Select } from 'antd';
import { Asset } from '@/src/components/pickers/AssetPicker';
import { Department } from '@/src/components/pickers/DepartmentPicker';
import { useEffect, useState } from 'react';
import Api from '@api';
import Icon from '../Icon';
import SystemLogo from '../SystemLogo';

export function transformAssetScope(item) {
  return {
    scope: {
      ...([4, 5, 6].includes(+item.scope)
        ? { assets: item.platform_versions || [] }
        : {
            assets:
              item.assets && item.assets !== 'null' && item.assets !== null
                ? Array.isArray(item.assets)
                  ? item.assets
                  : item.assets.split(',').map((i) => +i)
                : []
          }),
      assetFilter: +item.scope
    }
  };
}

export function transformAssetScopeForServer(item, useArray = false, useCamelCase = false) {
  return {
    scope: (item.scope || {}).assetFilter,
    ...([4, 5, 6].includes((item.scope || {}).assetFilter)
      ? {
          ...(useCamelCase
            ? { platformVersions: (item.scope || {}).assets || [] }
            : { platform_versions: (item.scope || {}).assets || [] })
        }
      : {
          assets: ((item.scope || {}).assets || []).length
            ? useArray
              ? item.scope.assets
              : item.scope.assets.join(',')
            : null
        })
  };
}

export const assetFilterOptions = [
  {
    label: 'All Endpoints',
    value: 1
  },
  {
    label: 'Specific Endpoints',
    value: 2
  },
  {
    label: 'Specific Departments',
    value: 3
  },
  {
    label: 'Windows',
    value: 4
  },
  {
    label: 'Linux',
    value: 5
  },
  {
    label: 'macOS',
    value: 6
  },
  {
    label: 'All Network Devices',
    value: 7
  },
  {
    label: 'Specific Network Devices',
    value: 8
  }
];

export default function AssetScopePicker({
  skipProvider,
  label = 'Specific Endpoint',
  name = 'assetFilter',
  subname = 'assets',
  gutter = 32,
  onPlatformChange,
  disabledScope,
  disabledScopeOptions = [7, 8]
}) {
  const form = Form.useFormInstance();

  let [version, setVersion] = useState({});

  let [devices, setDevices] = useState([]);

  let [assetFilterOptionsComputed, setAssetFilterOptionsComputed] = useState(assetFilterOptions);

  useEffect(() => {
    if ((disabledScopeOptions || []).length) {
      setAssetFilterOptionsComputed(
        assetFilterOptions.filter((i) => disabledScopeOptions.includes(i.value) === false)
      );
    }
  }, [disabledScopeOptions]);

  function getAssetVersions() {
    Api.get(`/inventory/asset/platform/versions`).then(({ result }) => {
      let versionMap = {};
      for (let key of Object.keys(result)) {
        versionMap[key] = result[key].map((i) => ({ value: i, label: i }));
      }
      setVersion(versionMap);
    });
  }

  function getDevices() {
    return Api.get(`/inventory/device`).then(({ result }) => {
      setDevices(
        result.map((i) => ({
          value: i.id,
          label: (
            <div className="flex items-center">
              <Icon
                name="inventory-host"
                className={`mr-2 text-lg ${
                  (i.current_status || '').toLowerCase() === 'offline'
                    ? 'text-danger'
                    : 'text-success'
                }`}
              />
              <SystemLogo name={i.vendor} className="w-4 mr-2 flex-shrink-0" type="vendor" />
              {i.host_name}
            </div>
          )
        }))
      );
    });
  }

  useEffect(() => {
    if ([4, 5, 6].includes(form.getFieldValue(name)) && !version[form.getFieldValue(name)]) {
      getAssetVersions();
    }
    if ([8].includes(form.getFieldValue(name)) && !version[form.getFieldValue(name)]) {
      getDevices();
    }
    // eslint-disable-next-line
  }, [form.getFieldValue(name)]);

  const children = (
    <Row gutter={gutter}>
      <Col span={12}>
        <Form.Item label={label} name={name} rules={[{ required: true }]}>
          <Select
            placeholder="Select One"
            options={assetFilterOptionsComputed}
            onChange={(assetFilter) => {
              form.setFieldValue(name, assetFilter);
              form.setFieldValue(subname, []);
              if (onPlatformChange) {
                onPlatformChange(assetFilter);
              }
            }}
            disabled={disabledScope}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        {[4, 5, 6].includes(form.getFieldValue(name)) && (
          <Form.Item label="OS Version" name={subname}>
            <Select
              mode="multiple"
              options={version[form.getFieldValue(name)]}
              placeholder="Select Version(s)"
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
        {[3, 4, 5, 6, 8].includes(form.getFieldValue(name)) === false &&
          disabledScopeOptions.includes(2) !== true && (
            <Form.Item label="Endpoints" name={subname}>
              <Asset.Picker
                mode="multiple"
                disabled={form.getFieldValue(name) !== 2}
                onChange={(assets) => form.setFieldValue(subname, assets)}
              />
            </Form.Item>
          )}
        {([8].includes(form.getFieldValue(name)) || disabledScopeOptions.includes(2)) && (
          <Form.Item label="Network Devices" name={subname}>
            <Select
              mode="multiple"
              placeholder="Select Devices"
              options={devices}
              disabled={form.getFieldValue(name) !== 8}
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
        {form.getFieldValue(name) === 3 && (
          <Form.Item label="Departments" name={subname}>
            <Department.Picker
              mode="multiple"
              onChange={(assets) => form.setFieldValue(subname, assets)}
            />
          </Form.Item>
        )}
      </Col>
    </Row>
  );

  if (skipProvider) {
    return children;
  }
  return (
    <Asset.Provider>
      <Department.Provider>{children}</Department.Provider>
    </Asset.Provider>
  );
}
