import { Row, Col } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import AnimatedRoutes from './AnimatedRoutes';
import { useState } from 'react';

export default function SplitPane({
  hasMenu = true,
  isMenuVisible,
  onVisibleChange,
  leftPane,
  rightPane
}) {
  const [internalVisibleChange, setInternalVisibleChange] = useState(
    isMenuVisible !== undefined ? false : true
  );
  return (
    <div className="flex-1 min-h-0 flex flex-col relative">
      <Row className="h-full">
        {hasMenu && (
          <Col
            span={3}
            className={`flex flex-col min-h-0 h-full overflow-auto ${
              isMenuVisible || internalVisibleChange ? '' : 'hidden'
            }`}>
            {leftPane}
          </Col>
        )}
        <AnimatedRoutes
          element={Col}
          span={24 - (hasMenu && (isMenuVisible || internalVisibleChange) ? 3 : 0)}
          className={`h-full relative flex flex-col min-h-0 ${hasMenu ? 'pl-4' : ''} h-full`}>
          {hasMenu ? (
            <div
              className="absolute left-0 top-3 h-2 w-2 z-10 cursor-pointer"
              onClick={() =>
                onVisibleChange
                  ? onVisibleChange(!isMenuVisible)
                  : setInternalVisibleChange((i) => !i)
              }>
              {isMenuVisible || internalVisibleChange ? (
                <LeftCircleOutlined className="text-lg" />
              ) : (
                <RightCircleOutlined className="text-lg" />
              )}
            </div>
          ) : null}
          {rightPane}
        </AnimatedRoutes>
      </Row>
    </div>
  );
}
