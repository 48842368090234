import api from '@api';
import Dayjs from 'dayjs';
import Moment from 'moment';
import OmitBy from 'lodash/omitBy';

import {
  transformAssetScope,
  transformAssetScopeForServer
} from '@/src/components/pickers/AssetScopePicker';

export const FORMAT = 'YYYY-MM-DD';

export const FORMAT_WITH_TIME = 'YYYY-MM-DD hh:mm:ss';

const END_POINT = `/patch/deployment`;

export const sortKeyMap = {
  name: 'name',
  display_name: 'displayName',
  createdTime: 'createdTime',
  deploymentType: 'deploymentType',
  deploymentStage: 'deploymentStage',
  retrycount: 'retryCount',
  totalTaskCount: 'totalTaskCount',
  completedTaskCount: 'completedTaskCount',
  pendingTaskCount: 'pendingTaskCount',
  lastExecutionTime: 'lastExecutionTime',
  nextExecutionTime: 'nextExecutionTime',
  successTaskCount: 'successTaskCount',
  failedTaskCount: 'failedTaskCount',
  updatedTime: 'updatedTime',
  startTime: 'startTime'
};

export const searchableColumns = [
  'name',
  'displayName',
  'removed',
  'description',
  'deploymentType',
  'deploymentStage',
  'totalTaskCount',
  'completedTaskCount',
  'completedTaskCount',
  'pendingTaskCount',
  'successTaskCount',
  'failedTaskCount'
];

export function transformPatchDeployementForClient(item) {
  return {
    id: item.id,
    name: item.name,
    displayName: item.displayName,
    createdById: item.createdById,
    createdTime: item.createdTime,
    updatedById: item.updatedTime,
    removed: item.removed,
    refId: item.refId,
    deploymentCategory: item.refModel,
    description: item.description,
    deploymentType: item.deploymentType,
    deploymentStage: item.deploymentStage,
    refIds: item.refIds,
    computerIds: item.computerIds,
    computerGroupIds: item.computerGroupIds,
    deploymentPolicyId: item.deploymentPolicyId,
    scope: item.scope,
    assets: item.assets,
    notifyEmailIds: item.notifyEmailIds,
    retrycount: item.retryCount,
    totalTaskCount: item.totalTaskCount,
    completedTaskCount: item.completedTaskCount,
    pendingTaskCount: item.pendingTaskCount,
    retryFailedConfiguration: item.retryCount > 0,
    lastExecutionTime: item.lastExecutionTime
      ? Dayjs(Moment.unix(item.lastExecutionTime).format(FORMAT_WITH_TIME))
      : undefined,
    nextExecutionTime: item.nextExecutionTime
      ? Dayjs(Moment.unix(item.nextExecutionTime).format(FORMAT))
      : undefined,
    ...transformAssetScope(item),
    successTaskCount: item.successTaskCount,
    failedTaskCount: item.failedTaskCount,
    updatedTime: item.updatedTime ? item.updatedTime : undefined,
    startTime: item.startTime ? item.startTime : undefined,
    lastExecutionTimeMs: item.lastExecutionTime ? item.lastExecutionTime : undefined
  };
}

export function transformPatchDeployementForServer(item) {
  let obj = {
    displayName: item.displayName,
    createdById: item.createdById,
    createdTime: item.createdTime,
    updatedById: item.updatedById,
    updatedTime: item.updatedTime,
    removed: item.removed,
    refId: item.refId,
    refModel: item.deploymentCategory,
    description: item.description,
    deploymentType: item.deploymentType,
    deploymentStage: item.deploymentStage || 'initiated',
    refIds: item.refIds,
    computerIds: item.computerIds,
    computerGroupIds: item.computerGroupIds,
    deploymentPolicyId: item.deploymentPolicyId,
    scope: item.scope,
    assets: item.asset,
    notifyEmailIds: item.notifyEmailIds,
    retryCount: item.retryFailedConfiguration ? item.retrycount : 0,
    totalTaskCount: item.totalTaskCount,
    completedTaskCount: item.completedTaskCount,
    pendingTaskCount: item.pendingTaskCount,
    lastExecutionTime: item.lastExecutionTime ? item.lastExecutionTime.unix() : undefined,
    nextExecutionTime: item.nextExecutionTime ? item.nextExecutionTime.unix() : undefined,
    ...transformAssetScopeForServer(item, true),
    successTaskCount: item.successTaskCount,
    failedTaskCount: item.failedTaskCount,
    totalTasks: item.totalTaskCount
  };

  obj = OmitBy(obj, (value) => value === null);

  return obj;
}

export function getAllPatchDeploymentApi(offset, size, sortFilter, { category }) {
  // return Promise.resolve({
  //   totalCount: 1,
  //   result: [{ software_name: 'name', software_id: 1 }]
  // });
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,

      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              sortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: (category
        ? [
            {
              operator: 'Equals',
              column: 'refModel',
              value: category
            }
          ]
        : []
      ).concat(
        sortFilter.searchTerm
          ? searchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map((i) => transformPatchDeployementForClient(i))
      };
    });
}

export function updatePatchDeploymentApi(item) {
  return api
    .put(`${END_POINT}/${item.parentId || item.id}`, {
      ...transformPatchDeployementForServer(item)
    })
    .then((data) => {
      return getPatchDeploymentApi(data.result);
    });
}

export function createPatchDeploymentApi(formData) {
  return api.post(`${END_POINT}`, transformPatchDeployementForServer(formData)).then((data) => {
    return getPatchDeploymentApi(data.result);
  });
}

export function getPatchDeploymentApi(id) {
  return api.get(`${END_POINT}/${id}`).then((result) => transformPatchDeployementForClient(result));
}

export function deletePatchDeploymentApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

const transformTask = (task) => ({
  id: task.id,
  displayName: task.displayName,
  taskStatus: task.taskStatus,
  result: atob(task.taskResult),
  createdBy: task.createdById,
  createdAt: task.createdTime,
  type: task.refModel,
  endpoint: task.agentId,
  assetName: task.assetName,
  agentId: task.agentId,
  updatedTime: task.updatedTime

  // iconFile: transformPackageIcon(task)
});

const taskSearchableColumns = ['displayName', 'taskResult', 'displayName', 'assetName'];

const taskSortKeyMap = {
  displayName: 'displayName',
  taskStatus: 'taskStatus',
  result: 'taskResult',
  createdAt: 'createdTime',
  type: 'refModel',
  endpoint: 'agentId',
  assetName: 'assetName',
  agentId: 'agentId',
  updatedTime: 'updatedTime'
};

export function getAllDeploymentTasktsApi(offset, size, sortFilter, { deploymentId }) {
  return api
    .post(`/patch/agent/task/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              taskSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        {
          operator: 'Equals',
          column: 'deployment_id',
          value: deploymentId.toString()
        }
      ].concat(
        sortFilter.searchTerm
          ? taskSearchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : []
      )
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transformTask)
      };
    });
}
