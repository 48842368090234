class TokenRefresher {
  isRefreshing = false;

  refreshCallbacks = [];

  errorCallbacks = [];

  _apiClient = null;

  constructor(apiClient) {
    this._apiClient = apiClient;
  }

  onRefresh(callback) {
    this.refreshCallbacks.push(callback);
  }

  refresh() {
    const token = JSON.parse(localStorage.getItem('token'));
    const refreshToken = token.refresh_token;
    if (!refreshToken) {
      this.triggerError(new Error('Refresh token is not supported'));
      return;
    }
    if (this.isRefreshing) {
      return;
    }
    this.isRefreshing = true;
    this._apiClient.removeHeader('Authorization');
    this._apiClient
      .post(
        `/token`,
        {
          'refresh-token': refreshToken
        },
        {
          notify: false
        }
      )
      .then((data) => {
        this.isRefreshing = false;
        this.refreshTokenReceived({
          access_token: data['access-token'],
          refresh_token: data['refresh-token']
        });
      })
      .catch((e) => {
        this.isRefreshing = false;
        this.triggerError(e);
      });
  }

  onError(cb) {
    this.errorCallbacks.push(cb);
  }

  triggerError(e) {
    this.refreshCallbacks = [];
    this.errorCallbacks.forEach((cb) => cb(e));
  }

  refreshTokenReceived(token) {
    localStorage.setItem('auth.token', JSON.stringify(token));
    this.refreshCallbacks.forEach((cb) => cb(token));
    this.refreshCallbacks = [];
  }
}

export default TokenRefresher;
