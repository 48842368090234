import { Row, Col, Radio, Form } from 'antd';
import QueryEditor from './QueryEditor';
import QueryBuilder, { createQueryFromJsonContext } from './QueryBuilder';

export function transformQuery(item) {
  return {
    query_context: {
      type: item.report_pivot_type === 'basic' ? 'basic' : 'advanced',
      ...(item.report_pivot_type === 'basic'
        ? {
            json_query: item.report_context,
            report_query: createQueryFromJsonContext(item.report_context)
          }
        : {
            raw_query: item.report_query
          })
    }
  };
}

export function transformQueryForServer(data) {
  return {
    report_pivot_type: data.type,
    ...(data.type === 'basic'
      ? {
          report_context: data.json_query,
          report_query: createQueryFromJsonContext(data.json_query)
        }
      : {
          report_query: data.raw_query
        })
  };
}

export default function QueryDataPicker({ disabled }) {
  const form = Form.useFormInstance();

  let name = ['query_context'];

  let isBasicEditor = form.getFieldValue([...name, 'type']) === 'basic';

  return (
    <Row>
      <Col span={24}>
        <Form.Item name={[...name, 'type']}>
          <Radio.Group
            onChange={(event) => {
              if (event.target.value === 'advanced') {
                form.validateFields([...name, 'json_query'], { recursive: true }).then((result) => {
                  form.setFieldValue(
                    [...name, 'raw_query'],
                    createQueryFromJsonContext(form.getFieldValue([...name, 'json_query']))
                  );
                });
              }
              form.setFieldValue([...name, 'type'], event.target.value);
            }}>
            <Radio value="basic">Basic</Radio>
            <Radio value="advanced">Advanced</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        {isBasicEditor ? (
          <Form.Item name={[...name, 'json_query']} label="" rules={[{ required: true }]}>
            <QueryBuilder disabled={disabled} name={[...name, 'json_query']} />
          </Form.Item>
        ) : (
          <Form.Item name={[...name, 'raw_query']} label="Query" rules={[{ required: true }]}>
            <QueryEditor />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
}
