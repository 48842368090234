import AssetScopePicker from '@/src/components/pickers/AssetScopePicker';
import { ComplianceBundle } from '@/src/components/pickers/ComplianceBundlePicker';
import { ComplianceRule } from '@/src/components/pickers/ComplianceRulePicker';
import { ConfigurationBundle } from '@/src/components/pickers/ConfigurationBundlePicker';
import { Configuration } from '@/src/components/pickers/ConfigurationPicker';
import { DeploymentPolicy } from '@/src/components/pickers/DeploymentPolicyPicker';
import { PackageBundle } from '@/src/components/pickers/PackageBundlePicker';
import { Package } from '@/src/components/pickers/PackagePicker';
import RecipientsPicker from '@/src/components/pickers/RecipientsPicker';
import { Form, Input, Radio, Row, Col, Select, InputNumber } from 'antd';
import { REFMODEL } from '@modules/settings/api/software-deployment/deployment-policy';
import DeploymentPolicyShower from '@modules/settings/components/software-deployment/DeploymentPolicyShower';

export default function DeployemntForm({ item = {}, disabled }) {
  const deploymentTypeOptions = ['install', 'uninstall', 'upgrade'].map((i) => ({
    value: i,
    label: i
  }));

  // const deploymentCategoryOptions = [
  //   { label: 'Software', value: 'package' },
  //   { label: 'Configuration', value: 'configuration' }
  // ];

  const form = Form.useFormInstance();

  const isCompliance = form.getFieldValue('deploymentCategory') === 'compliances';
  const isPackage = form.getFieldValue('deploymentCategory') === 'package';
  const isConfiguration = form.getFieldValue('deploymentCategory') === 'configuration';
  const isPatch = form.getFieldValue('deploymentCategory') === 'Patch';
  const isBundle = form.getFieldValue('resourceType') !== 'individual';

  const refMode = isCompliance
    ? REFMODEL.COMPLIANCES
    : isPackage
    ? REFMODEL.PACKAGE
    : isConfiguration
    ? REFMODEL.CONFIGURATION
    : isPatch
    ? REFMODEL.PATCH
    : undefined;

  const resourceTypeOptions = [
    ...(!isCompliance
      ? [
          {
            label: isPackage ? 'Application' : 'Configuration',
            value: 'individual'
          }
        ]
      : []),
    {
      label: `${
        isPackage ? 'Application' : isConfiguration ? 'Configuration' : 'Compliance'
      } Bundle`,
      value: 'bundle'
    }
  ];

  let resourcePicker =
    form.getFieldValue('resourceType') === 'individual' ? (
      isPackage ? (
        <Package.Picker disabled={disabled} target={form.getFieldValue(['scope', 'assetFilter'])} />
      ) : isConfiguration ? (
        <Configuration.Picker
          disabled={disabled}
          target={form.getFieldValue(['scope', 'assetFilter'])}
        />
      ) : (
        <ComplianceRule.Picker
          disabled={disabled}
          target={form.getFieldValue(['scope', 'assetFilter'])}
        />
      )
    ) : isPackage ? (
      <PackageBundle.Picker
        disabled={disabled}
        target={form.getFieldValue(['scope', 'assetFilter'])}
      />
    ) : isConfiguration ? (
      <ConfigurationBundle.Picker
        disabled={disabled}
        target={form.getFieldValue(['scope', 'assetFilter'])}
      />
    ) : (
      <ComplianceBundle.Picker
        disabled={disabled}
        target={form.getFieldValue(['scope', 'assetFilter'])}
      />
    );

  return (
    <div className="flex flex-col min-h-0 h-full overflow-y-auto overflow-x-hidden">
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item label="Deployment Name" name="displayName" rules={[{ required: true }]}>
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item
            label="Deployment Category"
            name="deploymentCategory"
            rules={[{ required: true }]}>
            <Select
              options={deploymentCategoryOptions}
              onChange={(value) => {
                form.setFieldValue('deploymentCategory', value);
                form.setFieldValue('packages', undefined);
              }}
            />
          </Form.Item>
        </Col> */}
        {isCompliance ? null : (
          <>
            <Col span={6}>
              <Form.Item label="Deployment Type" name="deploymentType" rules={[{ required: true }]}>
                <Select options={deploymentTypeOptions} />
              </Form.Item>
            </Col>
            <Col span={12} className="pt-6">
              <Form.Item label="" name="resourceType" rules={[{ required: true }]}>
                <Radio.Group
                  options={resourceTypeOptions}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(event) => {
                    form.setFieldValue('resourceType', event.target.value);
                    form.setFieldValue('packages', undefined);
                  }}
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24}>
          <Form.Item label=" " noStyle name="scope">
            <AssetScopePicker
              label="Scope"
              gutter={16}
              name={['scope', 'assetFilter']}
              subname={['scope', 'assets']}
              onPlatformChange={(value) => {
                form.setFieldValue(['packages'], undefined);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={`Select ${
              isPackage
                ? isBundle
                  ? 'Application Bundle'
                  : 'Application'
                : isConfiguration
                ? isBundle
                  ? 'Configuration Bundle'
                  : 'Configurations'
                : isBundle
                ? 'Compliance Framework'
                : 'Compliances'
            }`}
            name="packages"
            valuePropName={'targetKeys'}
            rules={[{ required: true }]}>
            {resourcePicker}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Deployment Policy"
            name="deploymentPolicyId"
            rules={[{ required: true }]}>
            <DeploymentPolicy.Picker
              refModel={refMode}
              value={item.deploymentPolicyId}
              setFullObject={(policy) => {
                form.setFieldValue('policyContext', policy);
              }}
              emitFullObject
            />
          </Form.Item>
        </Col>

        <Col span={24} className="mb-6">
          <DeploymentPolicyShower
            policyId={form.getFieldValue('deploymentPolicyId')}
            policyContext={form.getFieldValue('policyContext')}
          />
        </Col>
        {isCompliance ? null : (
          <Col span={12}>
            <Form.Item label="Retry Count" name="retryCount" rules={[{ required: true }]}>
              <InputNumber placeholder="Retry Count" precision={0} className="w-full" min={1} />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item label="Notify to" name="notifyEmailIds" rules={[{ required: true }]}>
            <RecipientsPicker />
          </Form.Item>
        </Col>
        <Col
          span={12}
          className="flex items-denter"
          key={form.getFieldValue('deploymentPolicyId') || 'id'}>
          <Form.Item label="policyContext" name="policyContext" style={{ visibility: 'hidden' }}>
            <Input placeholder="policyContext" disabled={form.getFieldValue('policyContext')} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
