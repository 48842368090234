import { Button, Tag, Drawer } from 'antd';
import { CrudProvider } from '@/src/hooks/crud';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import { User } from '@/src/components/pickers/UserPicker';
import {
  getAllBundlesApi,
  createBundleApi,
  updateBundleApi,
  deleteBundleApi,
  bulkDeleteBundleApi
} from '../bundles';
import BundleForm from '../components/BundleForm';
import { Package } from '@/src/components/pickers/PackagePicker';
import { Configuration } from '@/src/components/pickers/ConfigurationPicker';
import { getAllPackagesApi } from '../packages';
import { getAllConfigurationsApi } from '../configurations';
import Icon from '@/src/components/Icon';
import { useState } from 'react';
import Packages from './Packages';
import Configurations from './Configurations';
import BulkDeleteButton from '@/src/components/BulkDeleteButton';
// import { useState } from 'react';

export default function Bundles({ type }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      type: 'view_link'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      sortable: false,
      align: 'center',
      render({ record }) {
        return (
          <div className="text-xl">
            <Icon name={`platform_${record.os}`} />
          </div>
        );
      }
    },
    {
      title: type === 'package' ? 'Softwares' : 'Configurations',
      dataIndex: 'referenceIds',
      key: 'referenceIds',
      align: 'center',
      sortable: false,
      render({ record }) {
        return (
          <Button type="link" onClick={() => setShowDetailsFor(record)}>
            <Tag color="success">{record.referenceIds.length}</Tag>
          </Button>
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sortable: false,
      render({ record }) {
        return <User.Picker textOnly value={record.createdBy} disabled />;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime'
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      editPermissions: [
        type === 'package' ? constants.Update_Software : constants.Update_Configuration
      ],
      deletePermissions: [
        type === 'package' ? constants.Delete_Software : constants.Delete_Configuration
      ]
    }
  ];

  const [showDetailFor, setShowDetailsFor] = useState(null);

  const Provider = ({ children, os }) =>
    type === 'package' ? (
      <Package.Provider apiFn={(offset, size) => getAllPackagesApi(offset, size, { os })}>
        {children}
      </Package.Provider>
    ) : (
      <Configuration.Provider
        apiFn={(offset, size) => getAllConfigurationsApi(offset, size, { os })}>
        {children}
      </Configuration.Provider>
    );

  return (
    <User.Provider>
      <CrudProvider
        columns={columns}
        defaultFormItem={{
          type,
          os: 'windows'
        }}
        titleColumn="displayName"
        resourceTitle={`${type === 'package' ? 'Application' : 'Configuration'} Bundle`}
        disableFormScrolling
        hasSearch
        fetchFn={(...args) => getAllBundlesApi(...args, { category: type })}
        deleteFn={deleteBundleApi}
        createFn={createBundleApi}
        updateFn={updateBundleApi}
        allowSelection
        createAfterSlot={(_, { selectedItems, fetchData, setSelectedItems }) => (
          <BulkDeleteButton
            selectedItems={selectedItems}
            resourceTitle={`${type === 'package' ? 'Software' : 'Configuration'} Bundle`}
            className="ml-2"
            onConfirm={() =>
              bulkDeleteBundleApi(selectedItems).then(() => {
                setSelectedItems([]);
                return fetchData();
              })
            }
          />
        )}
        createSlot={(createFn) => (
          <PermissionChecker
            permission={
              type === 'package' ? constants.Create_Software : constants.Create_Configuration
            }>
            <Button type="primary" onClick={createFn}>
              Create
            </Button>
          </PermissionChecker>
        )}
        formFields={(item) => (
          <Provider os={item.os}>
            <BundleForm item={item} />
          </Provider>
        )}
      />
      <Drawer
        title={
          showDetailFor ? (
            <div className="flex items-center justify-between">
              <div>{showDetailFor.name}</div>
            </div>
          ) : (
            ``
          )
        }
        placement={'right'}
        width={'70%'}
        onClose={() => setShowDetailsFor(null)}
        destroyOnClose
        open={Boolean(showDetailFor) || Boolean(showDetailFor)}>
        {showDetailFor ? (
          type === 'package' ? (
            <Packages
              hideCategories
              disabled
              fetchFn={(offset, size, sortFilters) =>
                getAllPackagesApi(offset, size, { ...sortFilters, ids: showDetailFor.referenceIds })
              }
            />
          ) : (
            <Configurations
              disabled
              fetchFn={(offset, size, sortFilters) =>
                getAllConfigurationsApi(offset, size, {
                  ...sortFilters,
                  ids: showDetailFor.referenceIds
                })
              }
            />
          )
        ) : null}
      </Drawer>
    </User.Provider>
  );
}
