import { Form, Input, Col, Row, Radio, Divider, InputNumber, Checkbox } from 'antd';
// import Icon from '@/src/components/Icon';

// import Dayjs from 'dayjs';
import RecipientsPicker from '@/src/components/pickers/RecipientsPicker';
import { DeploymentPolicy } from '@/src/components/pickers/DeploymentPolicyPicker';
import DrawerSelectionItemPicker from '@components/pickers/DrawerSelectionItemPicker';
import { DRAWER_SELECTION_PICKER_TYPE } from '@components/helper/picker-helper';
// import { END_POINT } from '../api/patch-list';
import AssetScopePicker from '@/src/components/pickers/AssetScopePicker';
import PatchSeverityPicker from '@modules/patch/components/PatchSeverityPicker';
import PlatformPicker from '@/src/components/pickers/PlatformPicker';
import { ComputerGroups } from '@/src/components/pickers/ComputerGroupsPicker';
import PatchCategoryPicker from '@modules/patch/components/PatchCategoryPicker';
import { REFMODEL } from '@modules/settings/api/software-deployment/deployment-policy';
import DeploymentPolicyShower from '@modules/settings/components/software-deployment/DeploymentPolicyShower';

// import { DEPLOYMENT_TYPE } from '@src/modules/device-automation/deployments';

export default function AutoPatchDeploymentTestForm({ item = {}, type }) {
  // const forTest = type === DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST;
  const form = Form.useFormInstance();
  // const patchCategoryOptions = [
  //   { value: 'tools', label: 'Tools' },
  //   { value: 'hotfix', label: 'Hotfix' }
  // ];

  return (
    <ComputerGroups.Provider>
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item label="Name" name="displayName">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Category" name="patchCategories" rules={[{ required: true }]}>
            <PatchCategoryPicker mode="multiple" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Patch Severities" name="patchSeverities" rules={[{ required: true }]}>
            <PatchSeverityPicker
              mode="multiple"
              value={item.patchSeverities}
              onChange={(value) => {
                form.setFieldValue('patchSeverities', value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="OS Platform" name="platform" rules={[{ required: true }]}>
            <PlatformPicker
              disabled={item.id}
              onChange={(value) => {
                form.setFieldValue('platform', value);
                form.setFieldValue('productIds', []);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Applications" name="applicationType" rules={[{ required: true }]}>
            <Radio.Group
              optionType="button"
              onChange={(value) => {
                // form.setFieldValue('computerGroupIds', undefined);
              }}
              disabled={item.id}>
              <Radio value="all">All Applications</Radio>
              <Radio value="include">Include Applications</Radio>
              <Radio value="exclude">Exclude Applications</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {item.applicationType !== 'all' ? (
          <>
            <Col span={24}>
              <Form.Item label="Applications" name="productIds" rules={[{ required: true }]}>
                <DrawerSelectionItemPicker
                  type={DRAWER_SELECTION_PICKER_TYPE.APPLICATIONS}
                  apiEndpoint={`/patch/patch-affected-products/search`}
                  searchableColumns={['name']}
                  extraQualification={[
                    {
                      operator: 'Equals',
                      column: 'platform',
                      value: form.getFieldValue('platform')
                    }
                  ]}
                  disabledAddButton={!form.getFieldValue('platform')}
                />
              </Form.Item>
            </Col>
          </>
        ) : null}
        {/* <Col span={24}>
        <div className=" flex flex-col  my-2 text-primary text-sm grow-0">Patches</div>
        <Divider cla
          
        ssName="my-1 grow-0" />
      </Col> */}
        {/* {item.applicationType === 'all_application' ? ( */}
        <>
          <Col span={12}>
            <Form.Item name="computerFilterType" label="Computer Groups">
              <Radio.Group
                options={[
                  { label: 'All Computers', value: 'all_cg' },
                  { label: 'Specific Groups', value: 'specific_cg' },
                  { label: 'Scope', value: 'scope' }
                ]}
              />
            </Form.Item>
          </Col>
        </>
        {/* ) : null} */}
        {/* && item.applicationType === 'all_application'  */}
        {item.computerFilterType === 'specific_cg' ? (
          <>
            <Col span={12}>
              <Form.Item
                label="Computer Groups"
                name="computerGroupIds"
                rules={[{ required: true }]}>
                <ComputerGroups.Picker
                  mode="multiple"
                  value={item.computerGroupIds}
                  onChange={(computerGroupIds) => form.setFieldValue(item, computerGroupIds)}
                />
              </Form.Item>
            </Col>
          </>
        ) : null}
        {item.computerFilterType === 'scope' ? (
          <Col span={24}>
            <Form.Item label=" " noStyle name="scope">
              <AssetScopePicker
                label="Scope"
                gutter={16}
                name={['scope', 'assetFilter']}
                subname={['scope', 'assets']}
              />
            </Form.Item>
          </Col>
        ) : null}
        <Col span={24}>
          <div className=" flex flex-col  my-2 text-primary text-sm grow-0">
            Configuration Settings
          </div>
          <Divider className="my-1 grow-0" />
        </Col>
        <Col span={12} className="mb-4">
          <Form.Item
            label="Deployment Policy"
            name="deploymentPolicyId"
            rules={[{ required: true }]}
            className="mb-1">
            <DeploymentPolicy.Picker
              value={item.deploymentPolicyId}
              setFullObject={(policy) => {
                form.setFieldValue('policyContext', policy);
              }}
              emitFullObject
              refModel={REFMODEL.PATCH}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Deploy After Approval"
            name="deployAfterApprovalDays"
            rules={[{ required: true }]}>
            <InputNumber placeholder="Days" className="w-full" addonAfter="Days" />
          </Form.Item>
        </Col>

        <Col span={24} className="mb-6">
          <DeploymentPolicyShower
            policyId={form.getFieldValue('deploymentPolicyId')}
            policyContext={form.getFieldValue('policyContext')}
          />
        </Col>

        <Col span={12}>
          <Form.Item label="Notify to" name="notifyEmailIds">
            <RecipientsPicker />
          </Form.Item>
        </Col>
        <Col
          span={12}
          className="flex items-denter"
          key={form.getFieldValue('deploymentPolicyId') || 'id'}>
          <Form.Item label="policyContext" name="policyContext" style={{ visibility: 'hidden' }}>
            <Input placeholder="policyContext" disabled={form.getFieldValue('policyContext')} />
          </Form.Item>
        </Col>
        {/* {!forTest ? ( */}
        {/* <> */}

        <Col span={12}>
          <div className=" flex flex-col  my-2 text-primary text-sm grow-0">
            Third Party Updates
          </div>
          <Divider className="my-1 grow-0" />
        </Col>
        <Col span={12}>
          <div className=" flex flex-col  my-2 text-primary text-sm grow-0">Anti-virus Updates</div>
          <Divider className="my-1 grow-0" />
        </Col>
        <Col span={12}>
          <Form.Item
            label="Third Party Patch Severities"
            name="thirdPartyPatchSeverities"
            rules={[{ required: true }]}>
            <PatchSeverityPicker
              mode="multiple"
              value={item.patchSeverities}
              onChange={(value) => {
                form.setFieldValue('thirdPartyPatchSeverities', value);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Definition Update"
            name="includeDefinitionUpdate"
            valuePropName="checked">
            <Checkbox></Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className=" flex flex-col  my-2 text-primary text-sm grow-0">Driver Updates</div>
          <Divider className="my-1 grow-0" />
        </Col>
        <Col span={12}>
          <Form.Item label="Driver Updates" name="includeDriverUpdates" valuePropName="checked">
            <Checkbox></Checkbox>
          </Form.Item>
        </Col>
        {/* </> */}
        {/* ) : null} */}
      </Row>
    </ComputerGroups.Provider>
  );
}
