import { Col, Row, Popover, Tag } from 'antd';
import Capitalize from 'lodash/capitalize';
import Icon from '@/src/components/Icon';

export default function DeploymentPolicyShower({ policyContext, policyId }) {
  function getFormatedValues(str) {
    return Capitalize((str || '').replaceAll('_', ' '));
  }

  function convertTo12HourFormat(timeString) {
    // Step 1: Clean up the input by removing the extra colons
    const cleanedTime = timeString.replace(/::/g, ':');

    // Step 2: Split the time string into hours, minutes, and seconds
    const [hours, minutes] = cleanedTime.split(':').map(Number);

    // Step 3: Determine AM or PM and adjust the hours
    let period = 'AM';
    let adjustedHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        adjustedHours = hours - 12;
      }
    } else if (hours === 0) {
      adjustedHours = 12; // Midnight case
    }

    // Step 4: Return the formatted time in 12-hour format
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  return (
    <>
      {policyId && policyContext ? (
        <Row gutter={0} className="p-4  m-0 rounded-lg ant-tag  ant-tag-blue bordered flex">
          <Col span={24} className="mb-2">
            <span className="mr-2 text-sm ">Deployment Policy Info</span>
            {policyId && policyContext ? (
              <>
                <Popover
                  placement="right"
                  title={''}
                  content={() => {
                    return (
                      <>
                        <Row gutter={16} style={{ width: '400px' }}>
                          <Col span={12}>Type:</Col>
                          <Col span={12} className="font-bold">
                            {' '}
                            {getFormatedValues(policyContext.type)}
                          </Col>
                          <Col span={12}>Supported module:</Col>
                          <Col span={12} className="font-bold">
                            {' '}
                            {getFormatedValues(policyContext.refModel)}
                          </Col>

                          {policyContext.initiateDeploymentOn ? (
                            <>
                              <Col span={12}>Initiate Deployment On :</Col>
                              <Col span={12} className="font-bold">
                                {getFormatedValues(policyContext.initiateDeploymentOn)}
                              </Col>
                            </>
                          ) : null}

                          {policyContext.executionInterval ? (
                            <>
                              <Col span={12}>Interval :</Col>
                              <Col span={12} className="flex font-bold">
                                {policyContext.initiateDeploymentOn === 'recurring'
                                  ? 'Every '
                                  : null}
                                {policyContext.executionInterval.value}
                                {policyContext.executionInterval.unit}
                              </Col>
                            </>
                          ) : null}

                          {policyContext.deploymentDays ? (
                            <>
                              <Col span={12}>Deployment Days :</Col>
                              <Col span={12} className="whitespace-pre-line font-bold">
                                {(policyContext.deploymentDays || [])
                                  .map(getFormatedValues)
                                  .join(' ')}
                              </Col>
                            </>
                          ) : null}
                          {policyContext.deploymentTimeFrom ? (
                            <>
                              <Col span={12}>Deployment Time From :</Col>
                              <Col span={12} className="font-bold">
                                {convertTo12HourFormat(policyContext.deploymentTimeFromFormated)}
                              </Col>
                            </>
                          ) : null}

                          {policyContext.deploymentTimeTo ? (
                            <>
                              <Col span={12}>Deployment Time To :</Col>
                              <Col span={12} className="font-bold">
                                {convertTo12HourFormat(policyContext.deploymentTimeToFormated)}
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </>
                    );
                  }}>
                  <Icon name="info-circle" className="text-primaray cursor-pointer" />
                </Popover>
              </>
            ) : null}
          </Col>

          {policyContext.type ? (
            <>
              <span className="mr-2" span={1}>
                Type :
              </span>
              <span className="mr-2 flex" span={11}>
                <Tag color="processing">{getFormatedValues(policyContext.type)}</Tag>
              </span>
            </>
          ) : null}

          {policyContext.executionInterval ? (
            <>
              <span className="mr-2" span={1}>
                Interval :
              </span>
              <span className="mr-2 flex" span={11}>
                <Tag color="processing">
                  {policyContext.initiateDeploymentOn === 'recurring' ? 'Every ' : null}
                  {policyContext.executionInterval.value}
                  {policyContext.executionInterval.unit}
                </Tag>
              </span>
            </>
          ) : null}
          {policyContext.deploymentTimeFrom ? (
            <>
              <span className="mr-2" span={1}>
                Deployment Time From :
              </span>
              <span className="mr-2" span={11}>
                <Tag color="processing">
                  {convertTo12HourFormat(policyContext.deploymentTimeFromFormated)}
                </Tag>
              </span>
            </>
          ) : null}
          {policyContext.deploymentTimeTo ? (
            <>
              <span className="mr-2" span={1}>
                Deployment Time To :
              </span>
              <span className="mr-2" span={11}>
                <Tag color="processing">
                  {convertTo12HourFormat(policyContext.deploymentTimeToFormated)}
                </Tag>
              </span>
            </>
          ) : null}
        </Row>
      ) : (
        <span />
      )}
    </>
  );
}
