import { Table, Col, Row, Input, Drawer, Form, Button, Pagination } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { Resizable } from 'react-resizable';
import Merge from 'lodash/merge';
import { useCrud } from '../hooks/crud';
import ColumnSelection from './ColumnSelection';
import { useLocation, useParams } from 'react-router-dom';
import Icon from './Icon';
import { UserPreference } from './UserPreference';
import { useRef } from 'react';
import Loading from '@/src/components/Loading';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}>
      <th {...restProps} />
    </Resizable>
  );
};

export default function Crud({ createSlot, headingSlot, createAfterSlot, drawerTitle, ...props }) {
  const { updateMyPreference } = UserPreference.usePreference();
  const route = useLocation();
  const params = useParams();
  const formRef = useRef(null);
  const {
    columns,
    rawColumns,
    setColumns,
    keyFn,
    hasSearch,
    resourceTitle,
    formFields,
    dataChange,
    data,
    create,
    setSearchTerm,
    submitForm,
    formItem,
    edit,
    setFormItem,
    closeDrawer,
    resetForm,
    tableParams,
    loading,
    viewItem,
    processingForm,
    delete: deleteItem,
    selectedItems,
    fetchData,
    exportData,
    setSelectedItems,
    prependColumns,
    appendColumns
  } = useCrud();
  const uniqueRouteKey = Object.keys(params).reduce(
    (prev, key) => prev.replace(params[key], key),
    route.pathname
  );

  const handleResize =
    (index) =>
    (_, { size }) => {
      const newColumns = [
        ...columns.filter(
          (c) => c.key !== 'actions' && c.hidden !== true && c.isColumnHidden !== true
        )
      ];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      setColumns([...newColumns, ...columns.filter((c) => c.key === 'actions')]);
    };

  const uniqueId = `${uniqueRouteKey}-${resourceTitle || ''}`;

  const handleMouseUp = () => {
    if (!props.disableColumnSelection) {
      updateMyPreference({
        [uniqueId]: { columns }
      });
    }
  };

  function validateForm() {
    return formRef.current.validateFields();
  }

  return (
    <Row
      className={`${
        props.tableSlot ? 'p-2 flex flex-col flex-1 min-h-0' : 'p-2 flex flex-col h-full'
      } ${props.className}`}>
      {props.disableHeadingSlot ? null : (
        <Col
          span={24}
          className="pb-4 w-full flex justify-between items-center"
          style={{ flex: '0 auto' }}>
          {headingSlot && headingSlot()}
          {hasSearch && (
            <Input.Search
              placeholder="Search..."
              onSearch={setSearchTerm}
              style={{ width: 200 }}
              {...props.searchboxProps}
            />
          )}
          <div className={`inline-flex items-center ml-auto ${props.createContainerClasses || ''}`}>
            {props.beforeCreateSlot &&
              props.beforeCreateSlot(create, { selectedItems, data, setSearchTerm, fetchData })}
            {props.disableRefresh ? null : (
              <Button type="primary" className="mr-2" ghost onClick={fetchData}>
                <Icon name="refresh" />
                Refresh
              </Button>
            )}
            {props.disableExport || props.tableSlot ? null : (
              <Button type="primary" className="mr-2" ghost onClick={exportData}>
                <ExportOutlined />
                Export
              </Button>
            )}
            {createSlot &&
              createSlot(create, {
                selectedItems,
                data,
                setSearchTerm,
                fetchData,
                setSelectedItems
              })}
            {createAfterSlot &&
              createAfterSlot(create, { selectedItems, data, fetchData, setSelectedItems })}
            {!props.disableColumnSelection && (
              <ColumnSelection
                rawColumns={rawColumns}
                uniqueId={uniqueId}
                columns={columns}
                onChange={setColumns}
              />
            )}
          </div>
        </Col>
      )}
      {(formItem || viewItem) && (
        <Drawer
          {...(props.drawerProps || {})}
          title={
            drawerTitle
              ? drawerTitle(formItem || viewItem, formItem ? 'edit' : 'view')
              : `
          ${
            formItem && formItem.id !== undefined && formItem.id !== null
              ? 'Edit'
              : viewItem
              ? 'View'
              : props.createDrawerLabel || 'Create'
          } ${resourceTitle}`
          }
          placement={props.drawerPlacement || 'right'}
          width={props.formDrawerWidth || '50%'}
          size={props.drawerSize}
          onClose={closeDrawer}
          contentWrapperStyle={props.drawerContentWrapperStyle}
          destroyOnClose
          maskClosable={false}
          open={Boolean(formItem) || Boolean(viewItem)}>
          <Form
            layout="vertical"
            className="h-full"
            disabled={Boolean(viewItem)}
            requiredMark={!viewItem}
            ref={formRef}
            onFinish={submitForm}
            onValuesChange={(values) => {
              setFormItem(Merge({ ...formItem }, { ...values }));
            }}
            initialValues={formItem || viewItem}
            {...props.drawerFormProps}>
            <Row className="h-full">
              <div className="flex flex-1 min-h-0 flex-col h-full min-w-0">
                <div
                  className={`flex-1 min-h-0 ${
                    props.disableFormScrolling
                      ? ''
                      : props.enableHorizontalFormScrolling
                      ? 'overflow-auto'
                      : 'overflow-auto overflow-x-hidden'
                  }`}>
                  <Col span={24} className="flex-1 h-full">
                    {formFields
                      ? formFields(
                          formItem || viewItem,
                          (change) => edit({ ...formItem, ...change }),
                          {
                            disabled: Boolean(viewItem),
                            processingForm,
                            resetForm,
                            submitForm,
                            formItem,
                            validateForm
                          }
                        )
                      : null}
                  </Col>
                </div>
                <div className="flex-shrink-0">
                  {formItem && (
                    <Col span={24} className="text-right">
                      {props.formActions ? (
                        props.formActions({
                          processingForm,
                          resetForm,
                          submitForm(data) {
                            formRef.current.validateFields().then(() => {
                              submitForm(data);
                            });
                          },
                          formItem
                        })
                      ) : (
                        <>
                          <Button
                            type="primary"
                            loading={processingForm}
                            htmlType="submit"
                            className="mr-2">
                            {formItem && formItem.id !== undefined && formItem.id !== null
                              ? 'Update'
                              : 'Create'}
                          </Button>
                          <Button type="primary" ghost htmlType="reset" onClick={resetForm}>
                            Reset
                          </Button>
                        </>
                      )}
                    </Col>
                  )}
                </div>
              </div>
            </Row>
          </Form>
        </Drawer>
      )}
      <Col
        span={24}
        className={
          props.tableSlot ? 'flex flex-1 min-h-0 flex-col' : 'flex flex-1 min-h-0 flex-col'
        }>
        {props.tableSlot ? (
          <>
            {loading ? (
              <div className="flex flex-col flex-1 min-h-0 overflow-auto">
                <Loading ignoreFullScreenLoading />
              </div>
            ) : (
              <div className="flex flex-col flex-1 min-h-0 overflow-auto">
                {props.tableSlot({
                  ...props,
                  exportData,
                  deleteItem,
                  edit,
                  onChange: dataChange,
                  data,
                  rowKey: keyFn ? keyFn : (record) => record.id
                })}
              </div>
            )}

            {!props.disablePagination && tableParams.total > 0 ? (
              <div className="text-right">
                <Pagination
                  {...tableParams.pagination}
                  total={tableParams.total}
                  size="small"
                  onChange={(page, pageSize) =>
                    dataChange({
                      current: page,
                      pageSize: pageSize
                    })
                  }
                />
              </div>
            ) : null}
          </>
        ) : (
          <Table
            {...props}
            tableLayout="fixed"
            components={{
              header: {
                cell: ResizableTitle
              }
            }}
            rowSelection={
              props.allowSelection
                ? {
                    type: 'checkbox',
                    hideSelectAll: !props.allowSelectAll,
                    selectedRowKeys: selectedItems,
                    onSelect(record, selected) {
                      if (selected) {
                        setSelectedItems([...selectedItems, record.id]);
                      } else {
                        setSelectedItems(selectedItems.filter((s) => s !== record.id));
                      }
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                      if (selected) {
                        setSelectedItems(selectedRows.map((r) => r.id));
                      } else {
                        setSelectedItems([]);
                      }
                    }
                  }
                : false
            }
            showSorterTooltip={false}
            className={`crud-table ${props.tableClassNames}`}
            sortDirections={['ascend', 'descend']}
            columns={[
              ...prependColumns,
              ...columns
                .filter(
                  (c) => c.key !== 'actions' && c.hidden !== true && c.isColumnHidden !== true
                )
                .map((c, index) => ({
                  ...c,
                  onHeaderCell: (column) => ({
                    width: column.width,
                    onResize: handleResize(index),
                    onMouseUp: handleMouseUp
                  })
                })),
              ...columns.filter((c) => c.key === 'actions'),
              ...appendColumns
            ]}
            rowKey={keyFn ? keyFn : (record) => record.id}
            dataSource={data}
            size="small"
            pagination={
              props.disablePagination
                ? false
                : { ...tableParams.pagination, total: tableParams.total }
            }
            loading={loading}
            onChange={dataChange}
          />
        )}
      </Col>
    </Row>
  );
}
