import { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Trim from 'lodash/trim';
import api from '@api';
import { useLocalStorage } from './localStorage';
import { getMeApi } from '@/src/modules/settings/api/user-management/users';
import Loading from '@components/Loading';
import dateFormatter from '@utils/datetime';
import Moment from 'moment';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isUserResolved, setUserResolved] = useState(false);
  const [user, setUser] = useState(null);
  // eslint-disable-next-line
  const [token, setToken] = useLocalStorage('token', null);
  const navigate = useNavigate();

  if (token) {
    api.setHeaders({
      Authorization: `Bearer ${token.access_token}`
    });
  }

  // call this function to sign out logged in user
  const logout = async () => {
    if (token) {
      api
        .post(`/user/logout`, {
          'access-token': token.access_token,
          'refresh-token': token.refresh_token
        })
        .then(() => {
          setUser(null);
          setToken(null);
          setUserResolved(true);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 200);
        })
        .catch(() => {
          setUser(null);
          setToken(null);
          setUserResolved(true);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 200);
        });
    } else {
      setUser(null);
      setToken(null);
      setUserResolved(true);
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 200);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    const listener = () => {
      logout();
    };

    const refreshTokenListener = (event) => {
      setToken(event.detail);
      api.setHeaders({
        Authorization: `Bearer ${event.detail.access_token}`
      });
    };

    window.addEventListener('session-expired', listener);
    window.addEventListener('token-refresh', refreshTokenListener);

    if (token) {
      getMeApi()
        .then((user) => {
          setUser(user);
          setUserResolved(true);
        })
        .catch(() => logout());
    } else {
      setUserResolved(true);
    }
    return () => {
      window.removeEventListener('session-expired', listener);
      window.removeEventListener('token-refresh', refreshTokenListener);
    };
    // eslint-disable-next-line
  }, []);

  // call this function when you want to authenticate the user
  const performLogin = async (data) => {
    try {
      const serverResponse = await api.post('/token', {
        username: data.username,
        password: data.password
      });
      api.setHeaders({
        Authorization: `Bearer ${serverResponse['access-token']}`
      });
      setToken({
        access_token: serverResponse['access-token'],
        refresh_token: serverResponse['refresh-token']
      });
      const user = await getMeApi();
      setUser(user);
      if (!user.isValidLicense) {
        return navigate(`/settings/system-settings/license-management`);
      } else {
        navigate('/dashboard');
      }
    } catch (e) {
      throw new Error('Invalid username/password, Please try again');
    }
  };

  function formatDateTime(value, isInMs = true) {
    if (value === 0) {
      return;
    }
    if (Trim(String(value))) {
      return dateFormatter(
        isInMs ? value / 1000 : value,
        user.timezone || Moment.tz.guess(),
        user.datetimeformat || 'yyyy/MM/DD hh:mm:ss A'
      );
    }
    return value;
  }

  const value = useMemo(
    () => ({
      user,
      token,
      authenticate: performLogin,
      formatDateTime,
      logout
    }),
    // eslint-disable-next-line
    [user, token]
  );
  if (!isUserResolved) {
    return <Loading />;
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
