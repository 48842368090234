import { transformPackageIcon } from '@/src/helpers/package-icon';
import api from '@api';
import Moment from 'moment';
import { transformTimelineForServer } from '../../dashboard/widget-api';
import { transformTask } from '@modules/inventory/api/hosts';

const END_POINT = `/patch/compliance`;

const transformRule = (rule) => ({
  ...(rule.scriptFile && rule.scriptFile.refName
    ? {
        scriptFile: [
          {
            ref: rule.scriptFile.refName,
            name: rule.scriptFile.realName,
            uid: rule.scriptFile.refName,
            url: `/api/patch/download/${rule.scriptFile.refName}?mid=`,
            status: 'done'
          }
        ]
      }
    : {}),
  command: rule.command,
  key: rule.key,
  expectedOutput: rule.expectedOutput,
  condition: rule.condition,
  commandType: rule.commandType
});

const transform = (item) => ({
  id: item.id,
  name: item.displayName,
  bindings: item.bindings,
  ruleType: item.ruleType,
  status: item.disable ? false : true,
  description: item.description,
  audit: item.audit,
  impact: item.impact,
  createdBy: +item.createdById,
  createdAt: item.createdTime,
  remediation: item.remediation,
  tags: item.tags || [],
  executionType: item.executionType || 'command',
  cmdType: item.ruleExecutionType || 'cmd',
  rules: item.rules.map(transformRule)
});

const transformRuleForServer = (rule) => ({
  command: rule.command,
  ...(rule.commandType === 'reg' ? { key: rule.key } : {}),
  expectedOutput: rule.expectedOutput,
  commandType: rule.commandType,
  condition: rule.condition,
  ...(rule.executionType === 'script'
    ? {
        ...(rule.scriptFile &&
        rule.scriptFile.length &&
        (rule.scriptFile[0].ref || rule.scriptFile[0].response.ref)
          ? {
              scriptFile: {
                refName: rule.scriptFile[0].ref || rule.scriptFile[0].response.ref,
                realName: rule.scriptFile[0].name || rule.scriptFile[0].response.name
              }
            }
          : {})
      }
    : {})
});

const transformForServer = async (item) => {
  return Promise.resolve({
    ...(item.id ? { id: item.id } : {}),
    disable: item.status ? false : true,
    displayName: item.name,
    ruleType: item.ruleType,
    bindings: item.bindings,
    executionType: item.executionType,
    description: item.description,
    audit: item.audit,
    tags: item.tags,
    impact: item.impact,
    remediation: item.remediation,
    ruleExecutionType: item.cmdType || 'cmd',
    rules: item.rules.map((rule) =>
      transformRuleForServer({
        ...rule,
        commandType: item.cmdType !== 'reg' ? rule.commandType || item.cmdType : item.cmdType,
        executionType: item.executionType
      })
    )
  });
};

const ruleSortKeyMap = {
  name: 'displayName'
};

const rulesSearchableColumns = [
  'displayName',
  'bindings',
  'ruleType',
  'description',
  'audit',
  'remediation',
  'impact'
];

export function getAllRulesApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${
              ruleSortKeyMap[sortFilter.sort.field] || sortFilter.sort.field
            }`
          }
        : {}),
      qualification: [
        ...(sortFilter.ids
          ? [
              {
                operator: 'in',
                column: 'id',
                value: sortFilter.ids.join(',')
              }
            ]
          : []),
        ...(sortFilter.searchTerm
          ? rulesSearchableColumns.map((c) => ({
              operator: 'Contains',
              column: c,
              value: sortFilter.searchTerm
            }))
          : [])
      ]
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: (data.result || []).map(transform)
      };
    });
}

export function getRuleApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function updateRuleApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getRuleApi(data.result));
}

export function createRuleApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getRuleApi(data.result));
}

export function deleteRuleApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}

export function getHeatmapForDeploymentApi(deploymentId, timeline) {
  return api
    .post(`/patch/deployment/heatmap/${deploymentId}`, {
      ...(timeline ? { timeline: transformTimelineForServer(timeline) } : {})
    })
    .then((response) => {
      const date = Moment().format('YYYY-MM-DD');
      return Object.keys(response).map((key) => {
        const metadata = response[key].metadata;
        let rules = [];
        for (let ruleKey of Object.keys(response[key])) {
          if (ruleKey !== 'metadata') {
            rules.push({
              id: +ruleKey,
              date,
              count: response[key][ruleKey].success,
              name: response[key][ruleKey].metadata.name,
              bindings: response[key][ruleKey].metadata.bindings,
              description: response[key][ruleKey].metadata.description,
              total: response[key][ruleKey].total,
              success: response[key][ruleKey].success,
              unknown:
                response[key][ruleKey].total -
                (response[key][ruleKey].success + response[key][ruleKey].failed),
              failed: response[key][ruleKey].failed
            });
          }
        }

        return {
          id: +key,
          name: metadata.name,
          description: metadata.description,
          iconFile: transformPackageIcon(metadata),
          rules
        };
      });
    });
}

export function testComplianceApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`/patch/compliance/test/${item.assets}`, data);
    })
    .then((data) => getTaskContextApi(data.result.id));
}

export function getTaskContextApi(id) {
  return api.get(`/patch/agent/task/${id}`).then((data) => transformTask(data, true));
}
