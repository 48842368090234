// import Icon from '@/src/components/Icon';
// import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import PackageUploader from '@/src/components/PackageUploader';
import Repeater from '@/src/components/Repeater';
import PlatformPicker from '@/src/components/pickers/PlatformPicker';
import { Form, Input, Row, Col, Select, Divider } from 'antd';
import { getConfigurationsTagsApi } from '../configurations';

export default function ConfigurationForm({ disabled, item }) {
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    getConfigurationsTagsApi(true).then((tags) => setTagOptions(tags));

    // eslint-disable-next-line
  }, []);

  const form = Form.useFormInstance();

  const osArchOptions = ['x64', 'x86', 'amd64'].map((i) => ({ value: i, label: i }));

  const typeOptions = ['command', 'script'].map((i) => ({ value: i, label: i }));

  const typeMap = {
    ps: 'Powershell',
    reg: 'Registry',
    cmd: 'Command'
  };

  const commandTypeOptions = {
    windows: ['ps', 'reg', 'cmd'].map((i) => ({ value: i, label: typeMap[i] })),
    linux: ['cmd'].map((i) => ({ value: i, label: typeMap[i] })),
    mac: ['cmd'].map((i) => ({ value: i, label: typeMap[i] }))
  };

  return (
    <div className="flex flex-col min-h-0 h-full overflow-y-auto overflow-x-hidden">
      <Row gutter={32}>
        <Col span={12}>
          <Form.Item label="Configuration Name" name="displayName" rules={[{ required: true }]}>
            <Input placeholder="displayName" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Configuration Type"
            name="configurationType"
            rules={[{ required: true }]}>
            <Select
              options={typeOptions}
              disabled={item.id}
              placeholder="Please select"
              onChange={(value) => {
                form.setFieldValue('configurationType', value);
                form.setFieldValue('configurationActions', [{}]);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="OS" name="os" rules={[{ required: true }]}>
            <PlatformPicker
              disabled={item.id}
              placeholder="Please select"
              onChange={(value) => {
                form.setFieldValue('os', value);
                form.setFieldValue('configurationActions', [{}]);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Architecture" name="arch" rules={[{ required: true }]}>
            <Select options={osArchOptions} placeholder="Please select" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Tags" name="tags">
            <Select
              mode="tags"
              placeholder="Please select"
              defaultValue={form.getFieldValue('tags')}
              style={{ width: '100%' }}
              options={tagOptions}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Repeater
            name="configurationActions"
            canAdd={false}
            addBtnText="Add Configuration Action">
            {({ key, name, index, ...restField }, actions) => (
              <div className="flex flex-col" key={key}>
                <div className="flex items-center">
                  <div
                    className={`flex-1 mr-2 px-2 py-1 rounded border-solid border-border
                        ${disabled ? '' : 'bg-border'}`}>
                    <Row gutter={32}>
                      {form.getFieldValue('configurationType') === 'command' ? (
                        <>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, 'commandType']}
                              label="Command Type"
                              rules={[{ required: true }]}>
                              <Select
                                options={commandTypeOptions[form.getFieldValue('os')]}
                                placeholder="Please select"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={18}>
                            <Form.Item
                              {...restField}
                              name={[name, 'command']}
                              label="Command"
                              rules={[{ required: true }]}>
                              <Input placeholder="Command" />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <Col span={24}>
                          <Form.Item {...restField} name={[name, 'scriptFile']} label="Script File">
                            <PackageUploader />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </div>
                  {/* <div className="flex-shrink-0 flex items-center">
                    {!disabled && (
                      <Button
                        shape="circle"
                        className="mr-1"
                        title="Move Up"
                        disabled={index === 0}
                        onClick={() => actions.move(index, index - 1)}>
                        <ArrowUpOutlined />
                      </Button>
                    )}
                    {!disabled && (
                      <Button
                        shape="circle"
                        className="mr-1"
                        title="Move Down"
                        disabled={index === actions.length - 1}
                        onClick={() => actions.move(index, index + 1)}>
                        <ArrowDownOutlined />
                      </Button>
                    )}
                    {!disabled && (
                      <Button
                        shape="circle"
                        type="danger"
                        style={{ visibility: name === 0 ? 'hidden' : 'visible' }}
                        onClick={() => actions.remove(name)}>
                        <Icon name="close" className="text-danger" style={{ fontSize: '1.5rem' }} />
                      </Button>
                    )}
                  </div> */}
                </div>
                <Divider />
              </div>
            )}
          </Repeater>
        </Col>
      </Row>
    </div>
  );
}
