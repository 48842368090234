import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Inventory } from './inventory.svg';
import { ReactComponent as Compliance } from './compliance.svg';
import { ReactComponent as Vulnerability } from './vulnerability.svg';
import { ReactComponent as Fim } from './fim.svg';
import { ReactComponent as Remediation } from './remediation.svg';
import { ReactComponent as NotificationIcon } from './notification.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as ReportIcon } from './report.svg';
import { ReactComponent as Rootkit } from './rootkit.svg';
import { ReactComponent as Automation } from './automation.svg';
import { ReactComponent as Investigate } from './investigate.svg';
import { ReactComponent as Okr } from './okr.svg';

const icons = {
  dashboard: Dashboard,
  inventory: Inventory,
  compliance: Compliance,
  vulnerability: Vulnerability,
  fim: Fim,
  remediation: Remediation,
  notification: NotificationIcon,
  settings: SettingsIcon,
  report: ReportIcon,
  rootkit: Rootkit,
  automation: Automation,
  investigate: Investigate,
  okr: Okr
};

export default icons;
