import CodeMirror from '@uiw/react-codemirror';
import { sql, SQLDialect } from '@codemirror/lang-sql';
import { EditorView } from '@codemirror/view';
import { json } from '@codemirror/lang-json';
import { monokai } from '@uiw/codemirror-theme-monokai';
import { xcodeLight } from '@uiw/codemirror-theme-xcode';
import { UserPreference } from '../UserPreference';
import { useEffect, useRef, useState } from 'react';

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
const postgresSchema = {
  // host_info: ['sagar_id', 'sagar_name']
};

let autoComplete = SQLDialect.define({
  charSetCasts: true,
  doubleDollarQuotedStrings: true,
  operatorChars: '+-*/<>=~!@#%^&|`?',
  specialVar: '',
  keywords: ' ',
  types:
    'array binary bit boolean char character clob date decimal double float int integer interval large national nchar nclob numeric object precision real smallint time timestamp varchar varying ' +
    'bigint int8 bigserial serial8 varbit bool box bytea cidr circle precision float8 inet int4 json jsonb line lseg macaddr macaddr8 money numeric pg_lsn point polygon float4 int2 smallserial serial2 serial serial4 text timetz timestamptz tsquery tsvector txid_snapshot uuid xml'
});
const modes = {
  sql: sql({
    dialect: autoComplete,
    schema: postgresSchema,
    upperCaseKeywords: false
  }),
  json: json()
};

export default function QueryEditor({ disabled, mode = 'sql', height = '200px', ...props }) {
  const [loading, setLoading] = useState(true);
  const editorView = useRef(null);
  const editorState = useRef(null);
  const { preference } = UserPreference.usePreference();

  useEffect(() => {
    Promise.all([
      import('@/src/schema/osquery-full-schema.json'),
      import('@/src/schema/osquery-table-details.json')
    ]).then(([fullSchema, tableDetails]) => {
      modes.sql = sql({
        dialect: autoComplete,
        schema: fullSchema.default,
        upperCaseKeywords: false,
        tables: tableDetails.default
      });
      setLoading(false);
    });
  }, []);

  return loading ? (
    <div style={{ height }}>Loading...</div>
  ) : (
    <CodeMirror
      height={height}
      extensions={[modes[mode], EditorView.lineWrapping]}
      readOnly={disabled}
      editable={!disabled}
      theme={
        preference.theme === 'dark' || (preference.theme === 'auto' && mediaQuery.matches)
          ? monokai
          : xcodeLight
      }
      onCreateEditor={(view, state) => {
        editorState.current = state;
        editorView.current = view;
        // // eslint-disable-next-line
        // debugger;
      }}
      {...props}
    />
  );
}
