import { useEffect } from 'react';

import Picker from './Picker.jsx';
import buildPicker from './pickerBuilder';
import { getAllDeploymentPoliciesApi } from '@/src/modules/settings/api/software-deployment/deployment-policy.js';

const { Provider, contextHook } = buildPicker(
  getAllDeploymentPoliciesApi,
  DeploymentPolicyPicker,
  (i) => ({
    ...i,
    value: i.id,
    label: i.displayName
  })
);

function DeploymentPolicyPicker({
  value,
  onChange,
  setFullObject,
  emitFullObject,
  refModel,
  ...props
}) {
  useEffect(() => {
    if (value && emitFullObject) handleChange(value);

    // eslint-disable-next-line
  }, []);
  const { filteredOptions, allOptions, search } = contextHook();

  // Filter options based on the mode prop
  const getFilteredOptions = () => {
    if (!refModel) return filteredOptions || allOptions;

    // Apply custom filtering logic based on mode
    return [...(filteredOptions || Array.from(allOptions.values()))].filter((option) => {
      // Example: filter by mode if the option has a property that matches the mode
      return option.refModel === refModel || option.refModel === 'all'; // Adjust condition as per your use case
    });
  };

  const handleChange = (selectedValue) => {
    const selectedOption = allOptions.get(selectedValue);
    if (emitFullObject) {
      setFullObject(selectedOption);
      onChange(selectedValue); // Emit the whole object if the prop is true
    } else {
      onChange(selectedValue); // Emit only the value if the prop is false
    }
  };
  if (props.disabled && props.textOnly) {
    return allOptions.has(value) ? allOptions.get(value).label : null;
  }
  return (
    <Picker
      {...props}
      style={{ width: '100%' }}
      placeholder="Please Select"
      value={value}
      onChange={handleChange}
      onSearch={search}
      options={getFilteredOptions()}
    />
  );
}

export const DeploymentPolicy = {
  useUsers: contextHook,
  Provider: Provider,
  Picker: DeploymentPolicyPicker
};
