import ReactLoading from 'react-loading';

export default function Loading(ignoreFullScreenLoading) {
  return (
    <div
      className={`${
        ignoreFullScreenLoading ? 'h-full w-full' : 'h-screen w-screen'
      } flex flex-col items-center justify-center`}>
      <ReactLoading type={'cylon'} color={'var(--page-text-color)'} height={100} width={100} />
      <h4 className="text-white text-lg">Loading...</h4>
    </div>
  );
}
