import { useState } from 'react';

import { Button, Tag, Drawer } from 'antd';
import Icon from '@/src/components/Icon';

import Capitalize from 'lodash/capitalize';
import { CrudProvider } from '@/src/hooks/crud';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import { User } from '@/src/components/pickers/UserPicker';
// import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { DeploymentPolicy } from '@/src/components/pickers/DeploymentPolicyPicker';
import {
  getAllDeploymentsApi,
  createDeploymentApi,
  updateDeploymentApi,
  deleteDeploymentApi,
  DEPLOYMENT_TYPE
} from '../deployments';
import DeployemntForm from '../components/DeploymentForm';
import { Package } from '@/src/components/pickers/PackagePicker';
import { Configuration } from '@/src/components/pickers/ConfigurationPicker';
import { Asset } from '@/src/components/pickers/AssetPicker';
import Tasks from '../components/Tasks';
import { PackageBundle } from '@/src/components/pickers/PackageBundlePicker';
import { ConfigurationBundle } from '@/src/components/pickers/ConfigurationBundlePicker';
import PatchDeploymentForm from '@modules/patch/components/PatchDeploymentForm';
import AutoPatchDeploymentTestForm from '@modules/patch/components/AutoPatchDeploymentTestForm';

export default function Deployments({ type }) {
  const isAutopatchDeployment = [
    DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
    DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
  ].includes(type);

  const isPatchDeployment = [
    DEPLOYMENT_TYPE.PATCH,
    DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
    DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
  ].includes(type);

  const isCompliance = type === 'compliances';
  const [showTaskForItem, setShowTaskForItem] = useState(null);

  const tagColorMap = {
    draft: 'warning',
    initiated: 'processing',
    in_progress: 'processing',
    failed: 'error',
    cancelled: 'error',
    completed: 'success'
  };

  const stageColorMap = {
    install: 'success',
    uninstall: 'error',
    upgrade: 'warning'
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'name',
      type: 'view_link'
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'Type',
      dataIndex: 'deploymentType',
      align: 'center',
      key: 'deploymentType',
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={stageColorMap[record.deploymentType]}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              minWidth: '80px'
            }}>
            {record.deploymentType}
          </Tag>
        );
      }
    },
    {
      title: 'Stage',
      dataIndex: 'deploymentStage',
      align: 'center',
      key: 'deploymentStage',
      width: 80,
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={tagColorMap[record.deploymentStage]}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase',
              minWidth: '80px'
            }}>
            {Capitalize((record.deploymentStage || '').toLowerCase()).replaceAll('_', ' ')}
          </Tag>
        );
      }
    },
    {
      title: 'Policy',
      dataIndex: 'deploymentPolicyId',
      align: 'center',
      key: 'deploymentPolicyId',
      sortable: false,
      render({ record }) {
        return <DeploymentPolicy.Picker textOnly value={record.deploymentPolicyId} disabled />;
      }
    },
    {
      title: 'Pending',
      dataIndex: 'pendingTaskCount',
      key: 'pendingTaskCount',
      align: 'center',
      width: 50,
      sortable: false,

      render({ record }) {
        return (
          <Tag
            color={'warning'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {`${record.pendingTaskCount || 0}/${record.totalTasks || 0}`}
          </Tag>
        );
      }
    },
    // {
    //   title: 'Completed',
    //   dataIndex: 'completed',
    //   key: 'completed',
    //   align: 'center',
    //   width: 60,
    //   render({ record }) {
    //     return (
    //       <Tag
    //         color={'success'}
    //         className="inline-flex items-center justify-center"
    //         style={{
    //           textAlign: 'center',
    //           textTransform: 'uppercase'
    //         }}>
    //         {record.completedTasks}
    //       </Tag>
    //     );
    //   }
    // },
    {
      title: 'Succeeded Task',
      dataIndex: 'successTaskCount',
      key: 'successTaskCount',
      align: 'center',
      width: 60,
      sortable: false,

      render({ record }) {
        return (
          <Tag
            color={'success'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {`${record.successTaskCount || 0}/${record.totalTasks || 0}`}
          </Tag>
        );
      }
    },
    {
      title: 'Failed Task',
      dataIndex: 'failedTaskCount',
      key: 'failedTaskCount',
      align: 'center',
      width: 60,
      sortable: false,

      render({ record }) {
        return (
          <Tag
            color={'error'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {`${record.failedTaskCount || 0}/${record.totalTasks || 0}`}
          </Tag>
        );
      }
    }
  ]
    .concat(
      type === DEPLOYMENT_TYPE.PATCH
        ? [
            {
              title: 'Install After',
              width: 100,
              key: 'nextExecutionTime',
              dataIndex: 'nextExecutionTime',
              sortable: false,
              type: 'datetime'
            },
            {
              title: 'Expiry Date',
              width: 100,
              key: 'lastExecutionTimeMs',
              dataIndex: 'lastExecutionTimeMs',
              sortable: false,
              type: 'datetime'
            },
            {
              title: 'Execution finish time',
              dataIndex: 'updatedTime',
              key: 'updatedTime',
              type: 'datetime',
              hidden: true
            },
            {
              title: 'Start Time',
              dataIndex: 'startTime',
              key: 'startTime',
              type: 'datetime'
            }
          ]
        : [],
      [
        {
          title: 'Created By',
          dataIndex: 'createdBy',
          key: 'createdBy',
          sortable: false,
          render({ record }) {
            return <User.Picker textOnly value={record.createdBy} disabled />;
          }
        },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          type: 'datetime'
        },
        {
          title: '',
          dataIndex: 'actions',
          key: 'actions',
          editPermissions: [
            type === 'package'
              ? constants.Update_Software
              : isPatchDeployment
              ? constants.Update_Patch
              : constants.Update_Configuration
          ],
          deletePermissions: [
            type === 'package'
              ? constants.Delete_Software
              : isPatchDeployment
              ? constants.Delete_Patch
              : constants.Delete_Configuration
          ],
          canHaveButtons(record) {
            if (
              record.deploymentStage === 'draft' ||
              isAutopatchDeployment ||
              (isCompliance && record.isRecurringDeployment)
            ) {
              return ['edit', 'delete'];
            }
            return [];
          },

          prependAction({ record }) {
            return record.deploymentStage !== 'draft' && !isAutopatchDeployment ? (
              <>
                <Button
                  shape="circle"
                  type="link"
                  onClick={() => setShowTaskForItem(record)}
                  className="mr-2"
                  title="View Task">
                  <Icon name="eye" style={{ fontSize: '1.1rem' }} className="text-primary" />
                </Button>
              </>
            ) : null;
          }
        }
      ]
    )
    .filter((i) => {
      if (isAutopatchDeployment) {
        return ![
          'name',
          'deploymentType',
          'stage',
          'deploymentStage',
          'pending',
          'successTaskCount',
          'failedTaskCount'
        ].includes(i.key);
      }
      return true;
    });

  let BundleProvider = type === 'package' ? PackageBundle.Provider : ConfigurationBundle.Provider;

  return (
    <>
      <BundleProvider>
        <Asset.Provider>
          <Package.Provider>
            <Configuration.Provider>
              <DeploymentPolicy.Provider>
                <User.Provider>
                  <div className="flex flex-1">
                    <CrudProvider
                      columns={columns}
                      defaultFormItem={{
                        deploymentType: 'install',
                        deploymentCategory: type || 'package',
                        deploymentStage: 'draft',
                        retryCount: 3,
                        resourceType: 'individual',

                        ...(isAutopatchDeployment
                          ? {
                              applicationType: 'all',
                              computerFilterType: 'all_cg'
                            }
                          : {})
                      }}
                      titleColumn="displayName"
                      resourceTitle={`${
                        type === 'package'
                          ? 'Application'
                          : type === DEPLOYMENT_TYPE.PATCH
                          ? 'Patch'
                          : [DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST].includes(type)
                          ? 'Patch Test & Approve'
                          : [DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT].includes(type)
                          ? 'Zero Touch Deployment'
                          : 'Configuration'
                      } ${
                        [
                          DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
                          DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
                        ].includes(type)
                          ? ''
                          : 'Deployment'
                      } `}
                      disableFormScrolling={
                        ![
                          DEPLOYMENT_TYPE.PATCH,
                          DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
                          DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
                        ].includes(type)
                      }
                      hasSearch
                      fetchFn={(...args) => getAllDeploymentsApi(...args, { category: type })}
                      deleteFn={(...args) => deleteDeploymentApi(...args, { category: type })}
                      createFn={(...args) => createDeploymentApi(...args, { category: type })}
                      updateFn={(...args) => updateDeploymentApi(...args, { category: type })}
                      // expandable={{
                      //   expandedRowRender: (record, index, indent, expanded) => (
                      //     <div className="bg-seperator border-solid border-border rounded">
                      //       <Tasks deploymentId={record.id} />
                      //     </div>
                      //   ),
                      //   rowExpandable: (record) => record.deploymentStage !== 'draft',
                      //   expandIcon: ({ expanded, onExpand, record }) =>
                      //     record.deploymentStage !== 'draft' && (
                      //       <span className="text-label">
                      //         {expanded ? (
                      //           <DownOutlined
                      //             className="text-label"
                      //             onClick={(e) => onExpand(record, e)}
                      //           />
                      //         ) : (
                      //           <RightOutlined
                      //             className="text-label"
                      //             onClick={(e) => onExpand(record, e)}
                      //           />
                      //         )}
                      //       </span>
                      //     )
                      // }}
                      createSlot={(createFn) => (
                        <PermissionChecker
                          permission={
                            type === 'package'
                              ? constants.Create_Software
                              : [
                                  DEPLOYMENT_TYPE.PATCH,
                                  DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT_TEST,
                                  DEPLOYMENT_TYPE.AUTO_PATCH_DEPLOYMENT
                                ].includes(type)
                              ? constants.Create_Patch
                              : constants.Create_Configuration
                          }>
                          <Button type="primary" onClick={createFn}>
                            Create
                          </Button>
                        </PermissionChecker>
                      )}
                      formFields={(item, _, { disabled }) =>
                        type === DEPLOYMENT_TYPE.PATCH ? (
                          <PatchDeploymentForm item={item} disabled={disabled} />
                        ) : isAutopatchDeployment ? (
                          <AutoPatchDeploymentTestForm
                            item={item}
                            disabled={disabled}
                            type={type}
                          />
                        ) : (
                          <DeployemntForm item={item} disabled={disabled} />
                        )
                      }
                      formActions={({ processingForm, resetForm, submitForm, formItem }) => (
                        <>
                          {!isAutopatchDeployment ? (
                            <Button
                              type="primary"
                              loading={processingForm}
                              className="mr-2"
                              onClick={() => {
                                submitForm({
                                  deploymentStage:
                                    formItem.deploymentStage === 'draft'
                                      ? 'initiated'
                                      : formItem.deploymentStage || 'initiated'
                                });
                              }}>
                              Publish
                            </Button>
                          ) : null}
                          {formItem.deploymentStage === 'draft' && !isAutopatchDeployment ? (
                            <Button
                              type="primary"
                              loading={processingForm}
                              className="mr-2"
                              onClick={() => {
                                submitForm({
                                  deploymentStage: 'draft'
                                });
                              }}>
                              Save As Draft
                            </Button>
                          ) : null}

                          {isAutopatchDeployment ? (
                            <Button
                              type="primary"
                              loading={processingForm}
                              className="mr-2"
                              onClick={() => {
                                submitForm();
                              }}>
                              {formItem.id ? 'Update' : 'Create'}
                            </Button>
                          ) : null}

                          <Button type="primary" ghost htmlType="reset" onClick={resetForm}>
                            Reset
                          </Button>
                        </>
                      )}
                    />
                    <Drawer
                      title={`
        Tasks`}
                      placement="right"
                      width="60%"
                      onClose={() => setShowTaskForItem(null)}
                      destroyOnClose
                      open={Boolean(showTaskForItem)}>
                      {Boolean(showTaskForItem) && <Tasks deploymentId={showTaskForItem.id} />}
                    </Drawer>
                  </div>
                </User.Provider>
              </DeploymentPolicy.Provider>
            </Configuration.Provider>
          </Package.Provider>
        </Asset.Provider>
      </BundleProvider>
    </>
  );
}
