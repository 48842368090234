import React from 'react';
import { Popover, Tag } from 'antd'; // Adjust this import based on your popover implementation

const SelectedItemPills = ({
  maxItems = 2,
  value,
  wrap = true,
  usePopover = true,
  textOnly = false,
  vertical = false,
  tags = false
}) => {
  if (textOnly) {
    return <span>{value.join(',')}</span>;
  }

  const itemsToShow = value.filter(Boolean).slice(0, maxItems);
  const remainingItems = value.slice(maxItems);

  return (
    <div
      className={`flex-1 min-w-0 flex ${wrap ? 'flex-wrap' : ''} ${vertical ? 'flex-col' : ''} ${
        tags ? 'loose-tags-input' : ''
      }`}>
      {itemsToShow.length ? (
        <>
          {itemsToShow.map((item, index) => (
            <Tag
              key={`${index}-${item}`}
              color="orange"
              className={`${
                remainingItems.length > 0 ||
                (itemsToShow.length && index !== itemsToShow.length - 1)
                  ? 'mr-2 '
                  : 'mr-0'
              } text-ellipsis`}
              style={{ maxWidth: '75px' }}>
              {typeof item === 'object' ? item.text : item}
            </Tag>
          ))}
        </>
      ) : null}

      {remainingItems.length > 0 && (
        <span>
          {usePopover ? (
            <Popover
              placement="bottomRight"
              overlayClassName="readable-content-overlay picker-overlay"
              content={
                <div className="list h-full overflow-scroll">
                  {remainingItems.map((item, index) => (
                    <div key={`${item}-${index}`} className="text-ellipsis">
                      {typeof item === 'object' ? item.text : item}
                    </div>
                  ))}
                </div>
              }>
              <Tag color="orange" className="mr-0">
                +{remainingItems.length}
              </Tag>
            </Popover>
          ) : (
            <div className="mt-1 mb-1 mr-2 ant-tag ant-tag-has-color default rounded">
              +{remainingItems.length}
            </div>
          )}
        </span>
      )}
    </div>
  );
};

export default SelectedItemPills;
