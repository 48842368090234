import React, { lazy } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import AppLayout from '@/src/layouts/Layout.jsx';

const InventoryLayout = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './layout/InventoryLayout')
);
const Hosts = lazy(() => import(/* webpackChunkName: "inventory" */ './views/hosts/Hosts'));
const ViewHost = lazy(() => import(/* webpackChunkName: "inventory" */ './views/hosts/ViewHost'));
const Packages = lazy(() => import(/* webpackChunkName: "inventory" */ './views/Packages'));
const Browsers = lazy(() => import(/* webpackChunkName: "inventory" */ './views/Browsers'));
const LicenseManagement = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './views/LicenseManagement')
);
const ViewLicenseManagement = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './views/ViewLicenseManagement')
);

const SWMetering = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './views/SoftwareMetering')
);

const NetworkdeviceList = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './views/network-devices/List')
);

const ViewNetworkDevice = lazy(() =>
  import(/* webpackChunkName: "inventory" */ './views/network-devices/ViewNetworkDevice')
);

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="inventory" key="inventory" element={<AppLayout />}>
      <Route element={<InventoryLayout />}>
        <Route index element={<Navigate replace to="endpoints" />}></Route>
        <Route path="endpoints" element={<Outlet />}>
          <Route path="" element={<Hosts />} />
          <Route path=":id" element={<ViewHost />} />
        </Route>
        <Route path="network-devices" element={<Outlet />}>
          <Route path="" element={<NetworkdeviceList />} />
          <Route path=":id" element={<ViewNetworkDevice />} />
        </Route>

        <Route path="softwares" element={<Packages />} />
        <Route path="browsers" element={<Browsers />} />
        <Route path="software-metering" element={<SWMetering />} />
        <Route index element={<Navigate replace to="license-management" />}></Route>
        <Route path="license-management" element={<Outlet />}>
          <Route path="" element={<LicenseManagement />} />
          <Route path=":id" element={<ViewLicenseManagement />} />
        </Route>
      </Route>
    </Route>
  );
}
