import { ReactComponent as BitLocker } from './bitLocker_encryption.svg';
import { ReactComponent as FirewallStatus } from './firewall_status.svg';
import { ReactComponent as Lock } from './password_manager.svg';
import { ReactComponent as RansomwareProtection } from './ransomware_protection.svg';
import { ReactComponent as Risk } from './risk.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as Calendar } from './calender.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronUp } from './chevron-up.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Cpu } from './cpu.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Disk } from './disk.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Filter } from './grid_filter.svg';
import { ReactComponent as Memory } from './memory.svg';
import { ReactComponent as Ellipsis } from './options-vertical.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Sorting } from './sort-by.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as Agent } from './agent.svg';
import { ReactComponent as Policy } from './policy.svg';
import { ReactComponent as Database } from './database.svg';
import { ReactComponent as Integration } from './integration.svg';
import { ReactComponent as License } from './license.svg';
import { ReactComponent as AreaChart } from './area_chart.svg';
import { ReactComponent as Gauge } from './gauge.svg';
import { ReactComponent as Grid } from './grid.svg';
import { ReactComponent as HorizontalBar } from './horizontal_bar.svg';
import { ReactComponent as LineChart } from './line_chart.svg';
import { ReactComponent as Pie } from './pie.svg';
import { ReactComponent as VerticalBar } from './vertical_bar.svg';
import { ReactComponent as TableSettings } from './table_settings.svg';
import { ReactComponent as SettingsDropdown } from './settings_drodpwon.svg';
import { ReactComponent as Alert } from './alert.svg';
import { ReactComponent as Reload } from './reload.svg';
import { ReactComponent as Certificate } from './certificate.svg';
import { ReactComponent as Process } from './process.svg';
import { ReactComponent as RDP } from './rdp.svg';
import { ReactComponent as Terminal } from './terminal.svg';
import { ReactComponent as Department } from './department.svg';
import { ReactComponent as AgentVersion } from './agent-version.svg';
import { ReactComponent as LastSeen } from './last_seen.svg';
import { ReactComponent as Host } from './host.svg';
import { ReactComponent as EOL } from './eol.svg';
import { ReactComponent as LoggedinUser } from './loggedin_user.svg';
import { ReactComponent as SystemUser } from './system_user.svg';
import { ReactComponent as Startup } from './startup_items.svg';
import { ReactComponent as Os } from './os.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Threat } from './threat.svg';
import { ReactComponent as Configuration } from './configuration.svg';
import { ReactComponent as ConfigurationBundle } from './configuration-bundle.svg';
import { ReactComponent as ConfigurationDeployment } from './configuration-deployment.svg';
import { ReactComponent as App } from './app.svg';
import { ReactComponent as AppBundle } from './app-bundle.svg';
import { ReactComponent as AppDeployment } from './app-deployment.svg';
import { ReactComponent as Ip } from './ip.svg';
import { ReactComponent as InventoryHost } from './inventory-host.svg';
import { ReactComponent as QuickCheck } from './quick-check.svg';
import { ReactComponent as ComplianceRule } from './compliance-rule.svg';
import { ReactComponent as ComplianceDeployment } from './compliance-deployment.svg';
import { ReactComponent as ComplianceBundle } from './compliance-bundle.svg';
import { ReactComponent as Patch } from './patch.svg';
import { ReactComponent as RemoteWipeout } from './remote_wipeout.svg';
import { ReactComponent as Restart } from './restart.svg';
import { ReactComponent as Shutdown } from './shutdown.svg';
import { ReactComponent as FileExplorer } from './file_explorer.svg';
import { ReactComponent as KillProcess } from './kill_process.svg';
import { ReactComponent as LicenseManagment } from './license_managment.svg';
import { ReactComponent as TaskList } from './task-list.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Install } from './install.svg';
import { ReactComponent as InfoCircle } from './info-circle.svg';
import { ReactComponent as ActionHistory } from './action-history.svg';
import { ReactComponent as Exclamation } from './exclamation.svg';
import { ReactComponent as sync } from './sync.svg';
import { ReactComponent as wireless } from './wireless.svg';
import { ReactComponent as wireless_controller } from './wireless_controller.svg';
import { ReactComponent as load_balancer } from './load_balancer.svg';
import { ReactComponent as switch_icon } from './switch.svg';
import { ReactComponent as router } from './router.svg';
import { ReactComponent as ups } from './ups.svg';
import { ReactComponent as snmp_device } from './snmp_device.svg';
import { ReactComponent as printer } from './printer.svg';
import { ReactComponent as firewall } from './firewall.svg';
import { ReactComponent as interface_icon } from './interface.svg';

const icons = {
  add: Add,
  patch: Patch,
  host: Host,
  eol: EOL,
  'inventory-host': InventoryHost,
  os: Os,
  configuration: Configuration,
  'configuration-bundle': ConfigurationBundle,
  'configuration-deployment': ConfigurationDeployment,
  ip: Ip,
  app: App,
  'app-bundle': AppBundle,
  'app-deployment': AppDeployment,
  threat: Threat,
  loggedin_user: LoggedinUser,
  startup_items: Startup,
  system_user: SystemUser,
  alert: Alert,
  reload: Reload,
  certificate: Certificate,
  process: Process,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  calendar: Calendar,
  close: Close,
  cpu: Cpu,
  delete: Delete,
  disk: Disk,
  edit: Edit,
  filter: Filter,
  memory: Memory,
  ellipsis: Ellipsis,
  refresh: Refresh,
  sort: Sorting,
  bitlocker: BitLocker,
  'firewall-status': FirewallStatus,
  lock: Lock,
  'ransonware-protection': RansomwareProtection,
  risk: Risk,
  user: User,
  tag: Tag,
  agent: Agent,
  policy: Policy,
  database: Database,
  integration: Integration,
  license: License,
  area: AreaChart,
  grid: Grid,
  gauge: Gauge,
  horizontal_bar: HorizontalBar,
  vertical_bar: VerticalBar,
  pie: Pie,
  line_chart: LineChart,
  table_settings: TableSettings,
  settings_dropdown: SettingsDropdown,
  rdp: RDP,
  terminal: Terminal,
  department: Department,
  location: Location,
  agent_version: AgentVersion,
  last_seen: LastSeen,
  play: Play,
  'quick-check': QuickCheck,
  'compliance-rule': ComplianceRule,
  'compliance-deployment': ComplianceDeployment,
  'compliance-bundle': ComplianceBundle,
  'remote-wipeout': RemoteWipeout,
  restart: Restart,
  shutdown: Shutdown,
  'file-explorer': FileExplorer,
  'kill-process': KillProcess,
  sync: sync,
  'license-managment': LicenseManagment,
  'action-history': ActionHistory,
  'task-list': TaskList,
  eye: Eye,
  install: Install,
  'info-circle': InfoCircle,
  exclamation: Exclamation,
  wireless,
  firewall,
  'wireless controller': wireless_controller,
  'load balancer': load_balancer,
  switch: switch_icon,
  router,
  ups,
  'snmp device': snmp_device,
  printer,
  interface: interface_icon
};

export default icons;
