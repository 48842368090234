import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

const OKRLayout = lazy(() => import(/* webpackChunkName: "report" */ './layout/OKRLayout'));
const OKRList = lazy(() => import(/* webpackChunkName: "report" */ './views/OKRList'));

/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="okr" key="okr" element={<AppLayout />}>
      <Route element={<OKRLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<OKRList />}></Route>
        {/* <Route path="framework/:id" element={<FrameworkDetail />}></Route> */}
      </Route>
    </Route>
  );
}
