import { Tag } from 'antd';

export default function Severity({ severity, useTag = true, color }) {
  const severityNumberMap = {
    1: 'Critical',
    2: 'High',
    3: 'Medium',
    4: 'Low'
  };

  const tagColorMap = {
    'text-success': 'success',
    'text-danger': 'error',
    'text-warning': 'orange',
    'text-yellow': 'cyan'
  };

  const colorMap = {
    low: 'text-success',
    critical: 'text-danger',
    important: 'text-danger',
    high: 'text-warning',
    moderate: 'text-warning',
    medium: 'text-yellow',
    unspecified: 'text-yellow',
    clear: 'text-success'
  };

  let resolvedSeverity = typeof severity === 'number' ? severityNumberMap[severity] : severity;

  return (
    <div
      className={`flex items-center ${colorMap[color || (resolvedSeverity || '').toLowerCase()]}`}>
      {useTag ? (
        <Tag
          style={{ minWidth: '80px', textAlign: 'center' }}
          color={tagColorMap[colorMap[color || (resolvedSeverity || '').toLowerCase()]]}>
          {(resolvedSeverity || 'unrated').toUpperCase()}
        </Tag>
      ) : (
        <>
          <span>{(resolvedSeverity || 'unrated').toUpperCase()}</span>
        </>
      )}
    </div>
  );
}
