import React from 'react';
import { Layout, Dropdown, theme, Avatar, Radio, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '@hooks/auth';
import { UserPreference } from './UserPreference';
import Icon from './Icon';
import { PageHeader } from './PageHeader';
import Notifications from './Notifications';
import { ProductSetup } from './product-setup/ProductSetupProvider';
import EnrollBtn from './enroll/EnrollBtn';

export default function AppHeader({ handleThemeChange, collapsed, setCollapsed, ...props }) {
  const options = [
    {
      label: 'Auto',
      value: 'auto'
    },
    {
      label: 'Dark',
      value: 'dark'
    },
    {
      label: 'Light',
      value: 'light'
    }
  ];
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const { pageHeader } = PageHeader.usePageHeader();

  const { start: startSetup } = ProductSetup.setup();

  const { logout, user, token } = useAuth();

  const navigate = useNavigate();

  const { updateMyPreference } = UserPreference.usePreference();

  const performLogout = () => {
    logout().then(() => {
      navigate('/login');
    });
  };

  const items = [
    {
      key: '0',
      label: (
        <div
          className="flex items-center justify-between text-ellipsis"
          style={{ maxWidth: '250px' }}
          onClick={(e) => e.stopPropagation()}>
          <div className="flex min-w-0 text-ellipsis font-semibold text-lg">
            <div className="text-ellipsis">Welcome!! &nbsp;{`${user.name}`}</div>
          </div>
        </div>
      )
    },
    {
      key: '1',
      label: (
        <div className="flex items-center justify-between" onClick={(e) => e.stopPropagation()}>
          <span style={{ minWidth: '120px' }}>Theme</span>
          <Radio.Group
            options={options}
            value={props.theme}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              updateMyPreference({
                theme: e.target.value
              });
              handleThemeChange(e.target.value);
            }}
          />
        </div>
      )
    },
    {
      key: '2',
      shouldDisplay() {
        return user.id === 0;
      },
      label: (
        <div className="cursor-pointer" onClick={startSetup}>
          Start Product Setup
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className="cursor-pointer" onClick={performLogout}>
          Logout
        </div>
      )
    }
  ];

  const navigateToInvestigate = () => navigate('/investigate');

  return (
    <Layout.Header {...props} className="mr-4 px-2" style={{ backgroundColor: colorBgContainer }}>
      <div className="flex justify-between items-center h-full">
        <div className="flex items-center">{pageHeader}</div>
        <div className="flex items-center">
          {/* <div className="inline-flex mr-2">
            <Input.Search placeholder="Search..." size="medium" style={{ width: '300px' }} />
          </div> */}
          <div className="inline-flex mr-2">
            <EnrollBtn />
          </div>
          <div className="inline-flex mr-2">
            <Button
              type="primary"
              size="medium"
              onClick={navigateToInvestigate}
              className="gradient-btn">
              <Icon name="remediation" />
              Investigate
            </Button>
          </div>
          <div />
          <Notifications />
          <Button
            htmlType="button"
            type="link"
            onClick={() => navigate('/settings')}
            style={{ paddingLeft: '0.2rem', paddingRight: '0.5rem' }}>
            <Icon className="text-lg" name="settings" style={{ fontSize: '1.5rem' }} />
          </Button>
          {/* <div>Search and header here</div> */}
          <Dropdown
            menu={{ items: items.filter((i) => (i.shouldDisplay ? i.shouldDisplay() : true)) }}
            placement="bottomRight"
            trigger="click">
            <Avatar
              size="large"
              className="cursor-pointer"
              {...(user.profile
                ? { src: `/api/download?id=${user.profile[0].ref}&mid=${token.access_token}` }
                : { icon: <UserOutlined /> })}
            />
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  );
}
