import api from '@api';

const END_POINT = `/settings/logo`;

const transform = (item) => ({
  id: item.id,
  name: item.name,
  type: item.type,
  ...(item.system_image_ref
    ? {
        image: [
          {
            ref: item.system_image_ref,
            name: item.system_image
          }
        ]
      }
    : {
        image: undefined
      }),
  createdAt: item.created_time
});

const transformForServer = async (item) => {
  return Promise.resolve({
    name: item.name.toLowerCase(),
    type: item.type,
    ...(item.image && item.image.length > 0 && item.image[0].response
      ? {
          system_image_ref: item.image[0].response.ref,
          system_image: item.image[0].response.name
        }
      : {})
  });
};

const searchableColumns = ['name', 'type'];

export function getAllLogoApi(offset, size, sortFilter) {
  return api
    .post(`${END_POINT}/search`, {
      offset,
      size,
      ...(sortFilter && sortFilter.sort && sortFilter.sort.field
        ? {
            sortBy: `${sortFilter.sort.order === 'ascend' ? `-` : ''}${sortFilter.sort.field}`
          }
        : {}),
      qualification: sortFilter.searchTerm
        ? searchableColumns.map((c) => ({
            operator: 'Contains',
            column: c,
            value: sortFilter.searchTerm
          }))
        : []
    })
    .then((data) => {
      return {
        totalCount: data.totalCount,
        result: data.result.map(transform)
      };
    });
}

export function getLogoApi(id) {
  return api.get(`${END_POINT}/${id}`).then(({ result }) => transform(result));
}

export function getLogoByNameAndTypeApi(name, type) {
  return api
    .get(`${END_POINT}/fetch/ref?name=${name}&type=${type}`)
    .then(({ result }) => transform(result));
}

export function updateLogoApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.put(`${END_POINT}/${item.id}`, data);
    })
    .then((data) => getLogoApi(data.result));
}

export function createLogoApi(item) {
  return transformForServer(item)
    .then((data) => {
      return api.post(`${END_POINT}`, data);
    })
    .then((data) => getLogoApi(data.result));
}

export function deleteLogoApi(item) {
  return api.delete(`${END_POINT}/${item.id}`);
}
