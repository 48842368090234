import { Button, Badge, Popover } from 'antd';
import Icon from './Icon';
import { useEffect, useState } from 'react';
import { getSeverityWiseCountApi } from '../modules/alerts/api/alert-list';
import Severity from './Severity';
import { useNavigate } from 'react-router-dom';

export default function Notifications() {
  const [counts, setCounts] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getSeverityWiseCountApi(undefined).then((data) => {
      setCounts(data);
      setTotal((data.find((d) => d.severity === 'All') || {}).count || 0);
    });
  }, []);

  return (
    <Popover
      trigger="click"
      content={() => (
        <div className="flex flex-col">
          <div
            className="text-base flex justify-between cursor-pointer"
            onClick={() => navigate('/alerts')}>
            <div className="flex-1 mr-4 min-w-10">Total Alerts</div>
            <Severity severity={String(total)} />
          </div>
          {(counts || [])
            .filter((c) => c.severity !== 'All')
            .map((item) => (
              <div
                key={item.severity}
                className="text-base flex justify-between mt-4 cursor-pointer"
                onClick={() => navigate(`/alerts?severity=${item.severity}`)}>
                <Severity severity={item.severity} className="flex-1 mx-4 min-w-10" />
                <Severity color={item.severity.toLowerCase()} severity={String(item.count)} />
              </div>
            ))}
        </div>
      )}>
      <Badge
        count={total}
        status="error"
        offset={total > 0 ? [-10, 0] : undefined}
        className="inline-flex">
        <Button
          htmlType="button"
          type="link"
          style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }}>
          <Icon className="text-lg" name="notification" style={{ fontSize: '1.5rem' }} />
        </Button>
      </Badge>
    </Popover>
  );
}
