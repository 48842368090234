import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { getLogoByNameAndTypeApi } from '../modules/settings/api/system-settings/logo-management';

export default function SystemLogo({ type, name, ...props }) {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    getLogoByNameAndTypeApi((name || '').toLowerCase(), type).then((i) =>
      setLogo(i.image && i.image.length > 0 ? i.image[0] : null)
    );
  }, [name, type]);

  function navigateToAddLogo() {
    navigate(
      `/settings/system-settings/logo-management?zOperation=create&type=${type}&name=${name}&moveBack=1`
    );
  }

  return logo ? (
    <img
      {...props}
      src={`/api/download?id=${
        logo.ref
        // eslint-disable-next-line
      }&mid=${token.access_token}`}
      alt={name}
    />
  ) : props.disabled ? null : (
    <div {...props} onClick={navigateToAddLogo}>
      <img src="/images/addlogo_icon.png" alt="Add Logo" className="w-full cursor-pointer" />
    </div>
  );
}
