import Trim from 'lodash/trim';
import TrimEnd from 'lodash/trimEnd';
import { formatQuery } from 'react-querybuilder';

export default class QueryConverter {
  constructor(context) {
    this.context = context;
  }

  _buildSelect() {
    let { fn, alias } = this.context.aggregator || {};
    let column_select_clause = Trim(
      this.context.columns.reduce(
        (prev, { column, alias, dataType }) =>
          `${prev} ${
            dataType === 'timestamp'
              ? `TO_CHAR(TO_TIMESTAMP(${column}  / 1000), 'DD/MM/YYYY HH24:MI:SS')`
              : column
          } ${(alias || '').length ? `AS "${alias}"` : ''},`,
        ''
      ),
      ','
    );
    if (fn) {
      column_select_clause = `${fn}${
        (alias || '').length ? ` AS "${alias}"` : ''
      },${column_select_clause}`;
    }
    return `SELECT ${column_select_clause}`;
  }

  _buildFrom() {
    return `FROM ${this.context.table_name}`;
  }

  _buildWhere() {
    let { endpoint_column, archived } = this.context.tableOptions || {};
    let where_clause = ` WHERE ${endpoint_column ? `${endpoint_column} IN (ASSETS) ` : ''}`;
    if (((this.context.filters || {}).rules || []).length) {
      where_clause += ` ${endpoint_column ? 'AND ' : ''}${formatQuery(
        this.context.filters,
        'sql'
      )} `;
    }
    if (archived) {
      where_clause += `${
        ((this.context.filters || {}).rules || []).length || endpoint_column ? 'AND' : ''
      }  archived = 0`;
    }
    // if no condition found at all
    if (/WHERE\s*$/.test(where_clause)) {
      return '';
    }
    return TrimEnd(where_clause);
  }

  _buildGroupBy() {
    let { fn } = this.context.aggregator || {};
    let group_by_clause = ' ';
    if (fn) {
      group_by_clause += `GROUP BY ${Trim(
        this.context.columns.reduce((prev, { column }) => `${prev}${column},`, ','),
        ','
      )}`;
    }
    return group_by_clause;
  }

  _buildOrderBy() {
    let { column, direction } = this.context.sorting || {};
    let order_by_clause = ' ';
    if (column) {
      order_by_clause += `ORDER BY "${column}" ${direction || 'ASC'}`;
    }
    return order_by_clause;
  }

  _buildLimit() {
    let limit = this.context.limit;
    if (limit) {
      return ` LIMIT ${limit}`;
    }
    return '';
  }

  toSql() {
    let query = `${this._buildSelect()} ${this._buildFrom()}${this._buildWhere()}${this._buildGroupBy()}${this._buildOrderBy()}${this._buildLimit()}`;

    return Trim(query);
  }
}
